import React, { useCallback, useEffect, useState } from 'react'
import { IWebCurso } from '../../../models/Maintenance'
import { Divider, Grid } from 'semantic-ui-react'
import agent from '../../../api/agent'
import WebCursosForm from './WebCursosForm'

interface IWebCursoFormListProps {
    selectedWebId: number,
}

const WebCursoFormList: React.FC<IWebCursoFormListProps> = (props) => {
    const [webCursosList, setWebCursosList] = useState([] as IWebCurso[]);

    const refreshWebCursosList = useCallback(() => {
        agent.WebCursos.list(props.selectedWebId).then((webCursos) => setWebCursosList(webCursos));
    }, [props.selectedWebId])

    useEffect(() => {
        if (props.selectedWebId !== 0) {
            refreshWebCursosList()
        } else {
            setWebCursosList([] as IWebCurso[]);
        }
    }, [props.selectedWebId, refreshWebCursosList])

    return (
        <Grid>
            <Grid.Row key={props.selectedWebId} columns={2}>
                {webCursosList.map((webCurso) => (
                    <Grid.Column key={webCurso.id}>
                        <WebCursosForm webCurso={webCurso} handleRefreshWebCursosList={refreshWebCursosList} />
                        <Divider />
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    )
}

export default WebCursoFormList