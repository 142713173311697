import {
    action,
    makeObservable,
    observable,
    configure,
    runInAction,
} from "mobx";
import { createContext } from "react";

configure({ enforceActions: "always" });

class CommonStore {
    @observable loading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action setLoadingStatus = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        });
    };
}

export default createContext(new CommonStore());
