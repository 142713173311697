import React, { FormEvent, useEffect, useState } from 'react'
import { ICurso } from '../../../models/Maintenance'
import { Button, Divider, Form, Grid } from 'semantic-ui-react'
import agent from '../../../api/agent'
import DeleteItemModal from '../ConfirmDeleteModal'
import { toast } from 'react-toastify'

interface ICursoFormProps {
    selectedCursoId: number,
    cursosList: ICurso[],
    handleClearSelection: () => void,
    handleRefreshCursosList: () => void
}

const CursoForm: React.FC<ICursoFormProps> = (props) => {
    const [curso, setCurso] = useState({
        id: 0,
        nombre: '',
    } as ICurso);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        if (props.selectedCursoId !== 0) {
            agent.Cursos.details(props.selectedCursoId).then((selectedCurso) => setCurso(selectedCurso))
        } else {
            setCurso({
                id: 0,
                nombre: ''
            } as ICurso);
        }
    }, [props.selectedCursoId])

    const handleInputChange = (
        event: FormEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.currentTarget;
        setCurso({ ...curso, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            await agent.Cursos.save(curso);
            props.handleRefreshCursosList();
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {

        }
    };

    const handledOpenDeleteConfirmationModal = async () => {
        try {
            if (curso.id !== 0) {
                setOpenDeleteConfirmationModal(true);
            }
        } catch {

        }
    }

    const handledCloseDeleteConfirmationModal = async () => {
        setOpenDeleteConfirmationModal(false);
    }

    const deleteCurso = async () => {
        await agent.Cursos.delete(curso.id);
        props.handleRefreshCursosList();
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid columns={1}>
                <Grid.Row />
                <Divider horizontal>Curso</Divider>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            value={curso.nombre} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Button
                            floated="right"
                            positive
                            type="submit"
                            content="Guardar"
                        />
                        <Button
                            onClick={props.handleClearSelection}
                            floated="right"
                            type="button"
                            content="Cancelar"
                        />
                        <Button
                            onClick={handledOpenDeleteConfirmationModal}
                            negative
                            floated="left"
                            type="button"
                            content="Eliminar"
                            disabled={props.selectedCursoId === 0}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <DeleteItemModal
                visible={openDeleteConfirmationModal}
                message={`¿Desea borrar el curso ${curso.nombre}?`}
                onDelete={deleteCurso}
                onCancel={props.handleClearSelection}
                hideDeleteConfirmationModal={handledCloseDeleteConfirmationModal}
            />
        </Form>
    )
}

export default CursoForm