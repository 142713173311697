import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { Button, Checkbox, Divider, Modal } from 'semantic-ui-react';
import { IPrintExportCertificadosRequest } from '../../../models/certificado';
import certificadosStore from '../../../stores/CertificadosStore';
import CommonStore from '../../../stores/CommonStore';
import { showErrorToast, showSuccessToast } from '../../../functions/CommonFunctions';

interface IPrintExportCertificadosModalProps {
    visible: boolean;
    handleVisibleStatus: (open: boolean) => void;
    headerText: string,
    contentText: string,
    isExport: boolean,
    action: (request: IPrintExportCertificadosRequest) => Promise<void>,
}

const PrintExportCertificadosModal: React.FC<IPrintExportCertificadosModalProps> = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const certificadoStore = useContext(certificadosStore);
    const { certificadosSelectedList } = certificadoStore;
    const commonStore = useContext(CommonStore);
    const isSignedRef = useRef(null)

    const printCertificados = async () => {
        const printCertificadosRequest: IPrintExportCertificadosRequest = {
            ids: certificadosSelectedList,
            IsSigned: isSignedRef.current.inputRef.current.checked
        };

        try {
            setSubmitting(true);
            commonStore.setLoadingStatus(true);
            await props.action(printCertificadosRequest);
            showSuccessToast(props.isExport ? 'Se ha exportado correctamente' : 'Se ha impreso correctamente');
        }
        catch {
            showErrorToast(props.isExport ? 'Se ha producido un error al exportar' : 'Se ha producido un error al imprimir');
        }
        setSubmitting(false);
        commonStore.setLoadingStatus(false);
        props.handleVisibleStatus(false);
    }

    return (
        <Modal open={props.visible} size='small'>
            <Modal.Header>{props.headerText}</Modal.Header>
            <Modal.Content>
                {props.contentText}
                <Divider hidden />
                <Checkbox
                    toggle
                    name='signed'
                    label="Firmar PDF"
                    style={{
                        visibility: props.isExport
                            ? "visible"
                            : "hidden",
                    }}
                    ref={isSignedRef}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => props.handleVisibleStatus(false)}>
                    Cancelar
                </Button>
                <Button positive onClick={() => printCertificados()} disabled={submitting}>
                    Imprimir
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default observer(PrintExportCertificadosModal);