import React, { useContext, useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import CommonStore from "../stores/CommonStore";
import { observer } from 'mobx-react-lite';

const LoadingComponent: React.FC<{content?: string }> = ({
    content
}) => {
    const [visible, setVisible] = useState(false)
    const commonStore = useContext(CommonStore);

    useEffect(() => {
        setVisible(commonStore.loading);
    }, [commonStore.loading])

    if (visible) {
        return (
            <Dimmer active page>
                <Loader content={content} />
            </Dimmer>
        );
    }
}

export default observer(LoadingComponent)