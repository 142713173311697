import { useMsal } from "@azure/msal-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Dropdown, Container } from "semantic-ui-react";

export const NavBar: React.FC = () => {
  const { instance, accounts } = useMsal();

  let activeAccount;
  let isAdmin = true;

  if (instance && accounts.length > 0) {
    activeAccount = accounts[0];
    isAdmin =  activeAccount.idTokenClaims.groups?.includes(process.env.REACT_APP_AZURE_ADMINISTRATOR_GROUP_ID) || false;
  }

  const handleLogout = () => {
    instance.logout();
  };

  return (
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item name="Alumnos" as={NavLink} to="/alumnos" />
        <Menu.Item name="Certificados" as={NavLink} to="/certificados" />
        <Dropdown item text="Estadísticas">
          <Dropdown.Menu>
            <Dropdown.Item as={NavLink} to="/metricas">Metricas</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/comparativa">Comparativa</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {isAdmin && (
        <Dropdown item text="Mantenimiento">
          <Dropdown.Menu>
            <Dropdown.Item as={NavLink} to="/mantenimiento/plantillasemail">Plantillas Email</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/mantenimiento/plantillasimpresion">Plantillas Impresión</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/mantenimiento/webs">Webs</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/mantenimiento/cursos">Cursos</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/mantenimiento/webcursos">Web/Cursos</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        )}
        <Menu.Item name="Errores Syncronizador" as={NavLink} to= "/erroressync" />

        <Dropdown as={Menu.Item} item position="right" text={activeAccount ? activeAccount.name : 'Unknown'}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Menu>
  );
};

export default NavBar;
