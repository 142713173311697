import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef } from "react";
import { Button, Divider, Grid, Header, Input, Modal } from "semantic-ui-react";
import certificadosStore from "../../../stores/CertificadosStore";
import { IModallProps } from "./models/ModalProps";
import { getDatePresentationValue, getStringPresentationValue, showErrorToast, showSuccessToast } from "../../../functions/CommonFunctions";
import agent from "../../../api/agent";
import { IUpdateCertificadoRequest } from "../../../models/certificado";

const CertificadoDetails: React.FC<IModallProps> = (props) => {
  const certificadoStore = useContext(certificadosStore);
  const { certificado } = certificadoStore;
  const paisRef = useRef(null);
  const provinciaRef = useRef(null);
  const localidadRef = useRef(null);
  const codigoPostalRef = useRef(null);
  const direccionRef = useRef(null);
  const nombreAlumnoRef = useRef(null);
  const dniRef = useRef(null);
  const emailRef = useRef(null);
  const telefonoRef = useRef(null);

  useEffect(() => {
  }, [certificado]);

  if (!certificado) return;

  const handleSubmit = async () => {
    try {
      var updateRequest: IUpdateCertificadoRequest = {
        pais: paisRef.current.inputRef.current.value,
        provincia: provinciaRef.current.inputRef.current.value,
        localidad: localidadRef.current.inputRef.current.value,
        codigoPostal: codigoPostalRef.current.inputRef.current.value,
        direccion: direccionRef.current.inputRef.current.value,
        nombreAlumno: nombreAlumnoRef.current.inputRef.current.value,
        dni: dniRef.current.inputRef.current.value,
        email: emailRef.current.inputRef.current.value,
        telefono: telefonoRef.current.inputRef.current.value,
      }

      await agent.Certificados.save(certificado.certificadoId, updateRequest);
      showSuccessToast();
    } catch {
      showErrorToast();
    }
  };

  return (
    <Modal open={props.visible} size="small">
      <Modal.Content>
        <Divider horizontal>Alumno</Divider>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Nombre:</Header>
              <Input ref={nombreAlumnoRef} defaultValue={`${getStringPresentationValue(certificado.nombreAlumno)}`} />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Dni:</Header>
              <Input ref={dniRef} defaultValue={`${getStringPresentationValue(certificado.dniAlumno)}`} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Email:</Header>
              <Input ref={emailRef} defaultValue={`${getStringPresentationValue(certificado.emailAlumno)}`} />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Teléfono:</Header>
              <Input ref={telefonoRef} defaultValue={`${getStringPresentationValue(certificado.telefonoAlumno)}`} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>Curso</Divider>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Web:</Header> {`${certificado.web}`}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Curso:</Header> {`${certificado.curso}`}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Fecha:</Header>
              {`${getDatePresentationValue(certificado.fechaRealizacion)}`}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>Pago</Divider>
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Medio de Pago:</Header>{" "}
              {`${certificado.medioPago}`}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Estado de Pago:</Header>{" "}
              {`${certificado.estadoPago}`}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Fecha de Pago:</Header>{" "}
              {`${getDatePresentationValue(certificado.fechaPago)}`}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h4">Importe:</Header> {`${certificado.importeFinal}`}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Código de Descuento:</Header>{" "}
              {`${getStringPresentationValue(certificado.codigoDescuento)}`}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as="h4">Número de Transacción:</Header>{" "}
              {`${getStringPresentationValue(certificado.numeroTransaccion)}`}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Estado de Transacción:</Header>{" "}
              {`${getStringPresentationValue(certificado.estadoTransaccion)}`}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider horizontal>Envio</Divider>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Pais:</Header>
              <Input ref={paisRef} defaultValue={`${getStringPresentationValue(certificado.pais)}`} />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Provincia:</Header>
              <Input ref={provinciaRef} defaultValue={`${getStringPresentationValue(certificado.provincia)}`} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Localidad:</Header>
              <Input ref={localidadRef} defaultValue={`${getStringPresentationValue(certificado.localidad)}`} />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Código Postal:</Header>
              <Input ref={codigoPostalRef} defaultValue={`${getStringPresentationValue(certificado.codigoPostal)}`} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">Dirección:</Header>
              <Input ref={direccionRef} defaultValue={`${getStringPresentationValue(certificado.direccion)}`} />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Comentario Cliente:</Header>{" "}
              {`${getStringPresentationValue(certificado.comentario)}`}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleSubmit}>
          Guardar
        </Button>
        <Button negative onClick={() => props.handleVisibleStatus(false)}>
          Cerrar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default observer(CertificadoDetails);
