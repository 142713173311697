import React from "react";
import { observer } from "mobx-react-lite";
import { Router } from "./Router";
import { HomePage } from "./HomePage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { msalInstance } from "../authentication/msal";
import LoadingComponent from "./LoadingComponent";

const App: React.FC = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <UnauthenticatedTemplate>
        <HomePage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Router />
      </AuthenticatedTemplate>
      <ToastContainer />
      <LoadingComponent content="Cargando..." />
    </MsalProvider>
  );
};

export default observer(App);
