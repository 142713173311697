import { useContext, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import WebsList from '../webs/WebsList'
import WebCursosForm from './WebCursosFormList'
import agent from '../../../api/agent'
import { IWeb } from '../../../models/Maintenance'
import CommonStore from '../../../stores/CommonStore'
import { observer } from 'mobx-react-lite'

const WebCursosDashboard: React.FC = () => {
  const [selectedWebId, setSelectedWebId] = useState<number>(0)
  const [websList, setWebsList] = useState<IWeb[]>([]);

  const commonStore = useContext(CommonStore);

  useEffect(() => {
    commonStore.setLoadingStatus(true);
    agent.Webs.list().then((webs) => { 
      setWebsList(webs);
      commonStore.setLoadingStatus(false); 
    });
  }, [commonStore])

  const openEditWebCurso
    = (id: number) => {
      setSelectedWebId(id);
    }

  return (
    <Grid>
      <Grid.Column width={6}>
        <WebsList
          webList={websList}
          handleOpenEditWebCursosForm={openEditWebCurso}
        />
      </Grid.Column>
      <Grid.Column width={10}>
        <WebCursosForm
          selectedWebId={selectedWebId} />
      </Grid.Column>
    </Grid>
  )
}

export default observer(WebCursosDashboard)