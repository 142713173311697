import { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import { IDropdownOption } from "../../../models/dropdownOptions";

interface IWebCursosPlantillasImpresionFormModal {
    selectedWebCursoId: number,
    visible: boolean;
    handleVisibleStatus: (open: boolean) => void;
}

const WebCursosPlantillasImpresionFormModal: React.FC<IWebCursosPlantillasImpresionFormModal> = (props) => {
    const [webCursoPlantillas, setWebCursoPlantillas] = useState([] as number[]);
    const [plantillasImpresion, setPlantillasImpresion] = useState([] as IDropdownOption[]);
    const [disableSave, setDisableSave] = useState(false);

    useEffect(() => {
        if (props.visible) {
            agent.WebCursos.listPlantillasImpresion(props.selectedWebCursoId).then((plantillas) => setWebCursoPlantillas(plantillas));
            agent.Desplegables.printTemplates().then((plantillas) => setPlantillasImpresion(plantillas))
        }
    }, [props.selectedWebCursoId, props.visible]);

    const handleDropdownChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: number[] }
    ) => {
        setWebCursoPlantillas(data.value);
    };


    const handleSubmit = async () => {
        try {
            setDisableSave(true);
            await agent.WebCursos.savePlantillasImpresion(props.selectedWebCursoId, webCursoPlantillas);
            setDisableSave(false);
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {
            setDisableSave(false);
        }
    };

    return (
        <Modal open={props.visible} size="mini">
            <Modal.Header>Plantillas de Impresion</Modal.Header>
            <Modal.Content>
                <Form.Select
                    multiple
                    style={{ width: "100%" }}
                    options={plantillasImpresion.map(plantilla => ({ text: plantilla.text, value: plantilla.value }))}
                    onChange={handleDropdownChange}
                    value={webCursoPlantillas}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button positive disabled={disableSave} onClick={() => handleSubmit()}>
                    Guardar
                </Button>
                <Button negative onClick={() => props.handleVisibleStatus(false)}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default WebCursosPlantillasImpresionFormModal;