import { IDropdownOption } from "../models/dropdownOptions";

export const EstadosPagoDropdown: IDropdownOption[] = [
    { value: 0, text: 'Todos' },
    { value: 1, text: 'Pagados' },
    { value: 2, text: 'Pendientes' },
    { value: 3, text: 'rechazados' },
];

export const EstadosPagoPaid: number[] = [3, 4, 10];
export const EstadosPagoPending: number[] = [2, 9];
export const EstadosPagoRejected: number[] = [5, 6, 7];

export const MediosPagoDropdown: IDropdownOption[] = [
    { value: 1, text: 'Todos' },
    { value: 2, text: 'Paypal' },
    { value: 3, text: 'Tarjeta' },
    { value: 4, text: 'Transferencia' },
    { value: 6, text: 'Bizum' },
];