import { Fragment } from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { ICurso } from '../../../models/Maintenance';

interface ICursosListProps {
    handleOpenEditCursoForm: (id: number) => void;
    cursosList: ICurso[];
}

const CursosList: React.FC<ICursosListProps> = (props) => {
    return (
        <Fragment>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.cursosList.map((curso) => (
                        <Table.Row key={curso.id}>
                            <Table.Cell>{curso.nombre}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    name="edit"
                                    size="large"
                                    onClick={() => { props.handleOpenEditCursoForm(curso.id) }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Fragment>
    )
}

export default CursosList