import { useState } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import ChangeEstadoPagoModal from './modals/ChangeEstadoPagoModal'
import PrintExportCertificadosModal from './modals/PrintExportCertificadosModal'
import { exportCertificados, sendCertificadoEmail, sendCertificadosToPrinter, sendEtiquetasToPrinter, sendRecordatorioEmail } from "../../functions/CertificadosFunctions";
import SendEmailAlumnoModal from './modals/SendEmailAlumnoModal';

interface ICertificadosActionsProps {
    handleRefresh: () => Promise<void>;
}

const CertificadosDashboardActions = ({handleRefresh}:ICertificadosActionsProps) => {
    const [showEstadoPagoModal, setShowEstadoPagoModal] = useState(false);
    const [showPrintCertificadosModal, setShowPrintCertificadosModal] = useState(false);
    const [showPrintEtiquetasModal, setShowPrintEtiquetasModal] = useState(false);
    const [showExportCertificadosModal, setShowExportCertificadosModal] = useState(false);
    const [showSendRecordatorioEmailModal, setShowSendRecordatorioEmailModal] = useState(false);
    const [showSendCertificadoEmailModal, setShowSendCertificadoEmailModal] = useState(false);

    return (
        <Grid.Row centered>
            <Button className='action-button' onClick={() => setShowEstadoPagoModal(true)}>Cambiar Estado Pago</Button>
            <Button className='action-button' onClick={() => setShowPrintCertificadosModal(true)}>Imprimir Certificados</Button>
            <Button className='action-button' onClick={() => setShowPrintEtiquetasModal(true)}>Imprimir Etiquetas</Button>
            <Button className='action-button' onClick={() => setShowExportCertificadosModal(true)}>Exportar Certificados</Button>
            <Button className='action-button' onClick={() => setShowSendCertificadoEmailModal(true)}>Enviar Certificado Email</Button>
            <Button className='action-button' onClick={() => setShowSendRecordatorioEmailModal(true)}>Enviar Recordatorio Pago</Button>
            <ChangeEstadoPagoModal
                visible={showEstadoPagoModal}
                handleVisibleStatus={setShowEstadoPagoModal}
                handleRefresh={handleRefresh}
            />
            <PrintExportCertificadosModal
                visible={showPrintCertificadosModal}
                handleVisibleStatus={setShowPrintCertificadosModal}
                headerText="Imprimir Certificados"
                contentText="¿Desea imprimir los certificados seleccionados?"
                isExport={false}
                action={sendCertificadosToPrinter}
            />
            <PrintExportCertificadosModal
                visible={showPrintEtiquetasModal}
                handleVisibleStatus={setShowPrintEtiquetasModal}
                headerText="Imprimir Etiquetas"
                contentText="¿Desea imprimir las etiquetas para los certificados seleccionados?"
                isExport={false}
                action={sendEtiquetasToPrinter}
            />
            <PrintExportCertificadosModal
                visible={showExportCertificadosModal}
                handleVisibleStatus={setShowExportCertificadosModal}
                headerText="Exportar Certificados"
                contentText="¿Desea exportar los certificados seleccionados?"
                isExport={true}
                action={exportCertificados}
            />
            <SendEmailAlumnoModal
                visible={showSendRecordatorioEmailModal}
                handleVisibleStatus={setShowSendRecordatorioEmailModal}
                headerText="Enviar Recordatorio"
                contentText="Seleccione la plantilla y confirme el envío"
                isRecordatorio={true}
                action={sendRecordatorioEmail}
            />
            <SendEmailAlumnoModal
                visible={showSendCertificadoEmailModal}
                handleVisibleStatus={setShowSendCertificadoEmailModal}
                headerText="Enviar Certificado Email"
                contentText="Seleccione la plantilla y confirme el envío del certificado"
                isRecordatorio={false}
                action={sendCertificadoEmail}
            />
        </Grid.Row>
    )
}

export default CertificadosDashboardActions