import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Icon,
  Pagination,
  Table,
} from "semantic-ui-react";
import { ICertificado } from "../../models/certificado";
import certificadosStore from "../../stores/CertificadosStore";
import { getDatePresentationValue, getSortConfig } from "../../functions/CommonFunctions";
import CertificadosListIcons from "./CertificadosListIcons";
import CommonStore from "../../stores/CommonStore";
import { ISortConfig } from "../../models/Common";

interface ICertificadosListProps {
  handleShowCertificadoDetails: (open: boolean) => void;
}

const CertificadosList: React.FC<ICertificadosListProps> = (props) => {
  const certificadoStore = useContext(certificadosStore);
  const commonStore = useContext(CommonStore);
  const {
    certificadosList,
    certificadosSelectedList,
  } = certificadoStore;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { certificadoStore.clearCertificadosSelectedList() }, [])

  const openCertificadoDetails = async (certificadoId: number) => {
    commonStore.setLoadingStatus(true);
    await certificadoStore.loadcertificado(certificadoId);
    props.handleShowCertificadoDetails(true);
    commonStore.setLoadingStatus(false);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [sortConfig, setSortConfig] = useState<ISortConfig>({
    key: null,
    direction: null,
  });
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState<number | null>(null);

  const totalPages = Math.ceil(certificadosList.length / pageSize);
  const indexOfLastItem = Number(currentPage) * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;

  const getCurrentItemListSorted = (): ICertificado[] => {
    const key = sortConfig.key;
    const sortedData = certificadosList.slice().sort((a, b) => {
      if (sortConfig.direction === 'ascending') {
        return a[key as keyof ICertificado] > b[key as keyof ICertificado] ? 1 : -1;
      } else {
        return a[key as keyof ICertificado] < b[key as keyof ICertificado] ? 1 : -1;
      }
    });

    return sortedData.slice(indexOfFirstItem, indexOfLastItem);
  };

  const currentItems = getCurrentItemListSorted();

  const handleSelectAll = () => {
    const allPageSelected = checkAllPageSelected();
    currentItems.forEach((certificado) => {
      const isSelected = (allPageSelected) ||
        (!allPageSelected && !certificadosSelectedList.includes(certificado.id));
      isSelected && certificadoStore.setSelectedCertificados(certificado.id);
    });
  }

  const checkAllPageSelected = (): boolean => {
    return currentItems.every((certificado) => certificadosSelectedList.includes(certificado.id));
  };

  const handleSort = (clickedColumn: string) => {
    const newSortConfig = getSortConfig(clickedColumn, { ...sortConfig });
    setSortConfig(newSortConfig);
  };

  const handleMultiselection = (event: React.MouseEvent<HTMLInputElement>, rowIndex: number) => {
    let certificado: ICertificado;

    if (event.shiftKey && lastSelectedRowIndex !== null) {
      // Select the range of rows between the last selected row and the current row
      const start = Math.min(lastSelectedRowIndex, rowIndex);
      const end = Math.max(lastSelectedRowIndex, rowIndex);

      for (let i = start; i <= end; i++) {
        certificado = currentItems[i]
        !certificadosSelectedList.includes(certificado.id) && certificadoStore.setSelectedCertificados(certificado.id);
      }
    } else {
      // Single-click selection or no shift key, select only the clicked row
      certificado = currentItems[rowIndex];
      certificadoStore.setSelectedCertificados(certificado.id);
      setLastSelectedRowIndex(rowIndex);
    }
  };

  return (
    <Fragment>
      <Table celled sortable={currentItems.length < 5000}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell><Checkbox
              checked={checkAllPageSelected()}
              onChange={handleSelectAll}
            /></Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'nombreAlumno' ? sortConfig.direction : null} onClick={() => handleSort('nombreAlumno')}>Alumno</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'dniAlumno' ? sortConfig.direction : null} onClick={() => handleSort('dniAlumno')}>Dni</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'curso' ? sortConfig.direction : null} onClick={() => handleSort('curso')}>Curso</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'web' ? sortConfig.direction : null} onClick={() => handleSort('web')}>Web</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'medioPago' ? sortConfig.direction : null} onClick={() => handleSort('medioPago')}>Medio de Pago</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'estadoPago' ? sortConfig.direction : null} onClick={() => handleSort('estadoPago')}>Estado de Pago</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'codigoDescuento' ? sortConfig.direction : null} onClick={() => handleSort('codigoDescuento')}>Descuento</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'fecha' ? sortConfig.direction : null} onClick={() => handleSort('fecha')}>Fecha</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'fechaPago' ? sortConfig.direction : null} onClick={() => handleSort('fechaPago')}>Fecha de Pago</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'importe' ? sortConfig.direction : null} onClick={() => handleSort('importe')}>Importe</Table.HeaderCell>
            <Table.HeaderCell sorted={sortConfig.key === 'importeFinal' ? sortConfig.direction : null} onClick={() => handleSort('importeFinal')}>Importe Final</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {currentItems.map((certificado: ICertificado, index) => (
            <Table.Row key={certificado.id} style={{
              backgroundColor: certificado.convalidarFol
                ? "#F7D8C9"
                : "#FFF",
            }}>
              <Table.Cell style={{ display: "flex", gap: "2px" }}>
                <CertificadosListIcons certificado={certificado} />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  value={certificado.id}
                  checked={certificadosSelectedList.includes(certificado.id)}
                  onClick={(event) => handleMultiselection(event, index)}
                />
              </Table.Cell>
              <Table.Cell>{certificado.nombreAlumno}</Table.Cell>
              <Table.Cell>{certificado.dniAlumno}</Table.Cell>
              <Table.Cell>{certificado.curso}</Table.Cell>
              <Table.Cell>{certificado.web}</Table.Cell>
              <Table.Cell>{certificado.medioPago}</Table.Cell>
              <Table.Cell>{certificado.estadoPago}</Table.Cell>
              <Table.Cell>{certificado.codigoDescuento}</Table.Cell>
              <Table.Cell>
                {getDatePresentationValue(certificado.fecha)}
              </Table.Cell>
              <Table.Cell>
                {getDatePresentationValue(certificado.fechaPago)}
              </Table.Cell>
              <Table.Cell>{`${certificado.importe} €`}</Table.Cell>
              <Table.Cell>{`${certificado.importeFinal} €`}</Table.Cell>
              <Table.Cell>
                <Icon
                  className="iconButton"
                  name="eye"
                  size="large"
                  onClick={() => { openCertificadoDetails(certificado.id) }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination
        activePage={currentPage}
        onPageChange={(e, { activePage }) => setCurrentPage(activePage as number)}
        totalPages={totalPages}
        boundaryRange={0}
        siblingRange={1}
        ellipsisItem={null}
        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
      />
      <div className="ui labeled input">
        <div className="ui label label">Elementos por página</div>
        <Dropdown
          selection
          options={[
            { text: "50", value: 50 },
            { text: "100", value: 100 },
            { text: "200", value: 200 },
          ]}
          defaultValue={pageSize}
          onChange={(e, data) => setPageSize(data.value as number)}
        />
      </div>
    </Fragment>
  );
};

export default observer(CertificadosList);
