import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { ISortConfig } from "../models/Common";

export const getStringPresentationValue = (rawValue: any) => {
    return rawValue ? rawValue : "-";
};

export const getDatePresentationValue = (rawValue: any) => {
    return rawValue !== "0001-01-01T00:00:00"
        ? format(parseISO(rawValue), "dd/MM/yyyy HH:mm")
        : "-";
};

export const showSuccessToast = (message?: string) => {
    var infoText = message ? message : 'Cambios guardados correctamente';
    toast.success(infoText, {
        position: toast.POSITION.TOP_RIGHT
    });
}

export const showErrorToast = (message?: string) => {
    var infoText = message ? message : 'Se ha producido un error';
    toast.error(infoText, {
        position: toast.POSITION.TOP_RIGHT
    });
}

export const getSortConfig = (columnName: string, currentConfig: ISortConfig) => {
    if (currentConfig.key === columnName) {
        currentConfig.direction = currentConfig.direction === 'ascending' ? 'descending' : 'ascending';
    } else {
        currentConfig.key = columnName;
        currentConfig.direction = 'ascending';
    }

    return currentConfig;
};

export const getFilterDate = (dateRange: string, isStart: boolean) => {
    if (!dateRange) return null;

    const rawDate = isStart
      ? dateRange.split("-")[0].trim()
      : dateRange.split("-")[1].trim();
    const [day, month, year] = rawDate.split("/");
    const selectedDate = format(new Date(+year, +month - 1, +day), "yyyy-MM-dd");

    return selectedDate;
  };

 export const getDatePickerDefaultDates = (): Date[] => {
    
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    return [yesterday, today];
  }  