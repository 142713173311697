import { Route, Routes } from 'react-router-dom'
import CertificadosDashboard from '../features/certificados/CertificadosDashboard'
import EmailTemplatesDashboard from '../features/maintenance/emailTemplates/EmailTemplatesDashboard'
import WebsDashboard from '../features/maintenance/webs/WebsDashboard'
import CursosDashboard from '../features/maintenance/cursos/CursosDashboard'
import WebCursosDashboard from '../features/maintenance/webCursos/WebCursosDashboard'
import { Container } from 'semantic-ui-react'
import AlumnosDashboard from '../features/alumnos/AlumnosDashboard'
import ErroresSyncDashboard from '../features/erroresSync/erroresSyncDashboard'
import MetricasDashboard from '../features/metricas/metricasDashboard'
import NavBar from './NavBar'
import ComparativaDashboard from '../features/comparativa/ComparativaDashboard'
import PrintTemplatesDashboard from '../features/maintenance/printTemplates/PrintTemplatesDashboard'

export const Router = () => {
    return (
        <Container style={{ marginTop: "5em", width: "90%" }}>
            <NavBar />
            <Routes>
                <Route path="/" element={<CertificadosDashboard />} />
                <Route path="/alumnos" element={<AlumnosDashboard />} />
                <Route path="/certificados" element={<CertificadosDashboard />} />
                <Route path="/mantenimiento/plantillasemail" element={<EmailTemplatesDashboard />} />
                <Route path="/mantenimiento/plantillasimpresion" element={<PrintTemplatesDashboard />} />
                <Route path="/mantenimiento/webs" element={<WebsDashboard />} />
                <Route path="/mantenimiento/cursos" element={<CursosDashboard />} />
                <Route path="/mantenimiento/webcursos" element={<WebCursosDashboard />} />
                <Route path="/erroressync" element={<ErroresSyncDashboard />} />
                <Route path="/metricas" element={<MetricasDashboard />} />
                <Route path="/comparativa" element={<ComparativaDashboard />} />
            </Routes>
        </Container>
    )
}
