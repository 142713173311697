import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { Button, Grid, Input, Modal } from "semantic-ui-react";
import { IMapeoCampo } from "../../../models/Maintenance";
import agent from "../../../api/agent";
import { toast } from "react-toastify";

interface IWebCursosMapeosFormModal {
    selectedWebCursoId: number,
    visible: boolean;
    handleVisibleStatus: (open: boolean) => void;
}

const WebCursosMapeosFormModal: React.FC<IWebCursosMapeosFormModal> = (props) => {
    const [webCursoMapeos, setWebCursoMapeos] = useState([] as IMapeoCampo[]);
    const [disableSave, setDisableSave] = useState(false);

    useEffect(() => {
        if (props.visible)
            agent.MapeoCampos.list(props.selectedWebCursoId).then((mapeos) => setWebCursoMapeos(mapeos));
    }, [props.selectedWebCursoId, props.visible]);

    const handleInputChange = (
        event: FormEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.currentTarget;
        let mapeos = [...webCursoMapeos];
        let index = mapeos.findIndex((element) => {
            return element.campo === name;
        });
        mapeos[index].fieldNumber = value;

        setWebCursoMapeos(mapeos);
    };

    const handleSubmit = async () => {
        try {
            setDisableSave(true);
            await agent.MapeoCampos.save(webCursoMapeos);
            setDisableSave(false);
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {
            setDisableSave(false);
        }
    };

    return (
        <Modal open={props.visible} size="mini">
            <Modal.Header>Mapeo de Campos</Modal.Header>
            <Modal.Content>
                <Grid columns={1}>
                    {webCursoMapeos.map((mapeo) => (
                        <Grid.Row key={mapeo.id}>
                            <Input onChange={handleInputChange}
                                name={mapeo.campo}
                                label={mapeo.campo}
                                value={mapeo.fieldNumber}
                            />
                        </Grid.Row>
                    ))}
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button positive disabled={disableSave} onClick={() => handleSubmit()}>
                    Guardar
                </Button>
                <Button negative onClick={() => props.handleVisibleStatus(false)}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default observer(WebCursosMapeosFormModal);  