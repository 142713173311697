import { useContext, useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    Grid,
    Table,
} from "semantic-ui-react";
import CommonStore from '../../stores/CommonStore';
import { IErrorSync } from '../../models/ErrorSync';
import agent from '../../api/agent';

const ErroresSyncDashboard: React.FC = () => {
    const [erroresSync, setErroresSync] = useState<IErrorSync[]>([])
    const [selectedErrors, setSelectedErrors] = useState<number[]>([])
    const commonStore = useContext(CommonStore);

    useEffect(() => {
        commonStore.setLoadingStatus(true);
        agent.ErroresSync.list().then((errors) => {
            setErroresSync(errors);
            commonStore.setLoadingStatus(false);
        });

        const intervalId = setInterval(() => {
            commonStore.setLoadingStatus(true);
            agent.ErroresSync.list().then((errors) => {
                setErroresSync(errors);
                commonStore.setLoadingStatus(false);
            });
        }, 60000);
        return () => {
            clearInterval(intervalId);
        };
    }, [commonStore]);

    const updateSelectedErrorsList = (id: number) => {
        setSelectedErrors(prevSelected => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter(errorId => errorId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    }

    const reprocessSelectedErrors = async () => {
        await agent.ErroresSync.retry(selectedErrors);
    }

    return (
        <Grid>
            <Grid.Row centered>
                <Button onClick={async () => await reprocessSelectedErrors()}>Reprocesar Seleccionados</Button>
            </Grid.Row>
            <Grid.Row>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Web</Table.HeaderCell>
                            <Table.HeaderCell>Curso</Table.HeaderCell>
                            <Table.HeaderCell>Lead Id</Table.HeaderCell>
                            <Table.HeaderCell>Error</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {erroresSync.map((error: IErrorSync) => (
                            <Table.Row key={error.id}>
                                <Table.Cell>
                                    <Checkbox
                                        value={error.id}
                                        checked={selectedErrors.includes(error.id)}
                                        onChange={() => updateSelectedErrorsList(error.id)}
                                    />
                                </Table.Cell>
                                <Table.Cell>{error.nombreWeb}</Table.Cell>
                                <Table.Cell>{error.nombreCurso}</Table.Cell>
                                <Table.Cell>{error.leadId}</Table.Cell>
                                <Table.Cell>{error.error}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Grid.Row>
        </Grid>
    );
}

export default ErroresSyncDashboard