import React, { FormEvent, useEffect, useState } from 'react'
import { IPrintTemplate } from '../../../models/Maintenance'
import { Button, Form, Grid } from 'semantic-ui-react'
import agent from '../../../api/agent'
import DeleteItemModal from '../ConfirmDeleteModal'
import { toast } from 'react-toastify'

interface IPrintTemplateFormProps {
    selectedPrintTemplateId: number,
    handleClearSelection: () => void,
    handleRefreshPrintTemplateList: () => void
}

const PrintTemplatesForm: React.FC<IPrintTemplateFormProps> = (props) => {
    const initialValues: IPrintTemplate = {
        id: 0,
        nombre: '',
        nombreFichero: '',
        prefijoMatricula: '',
        texto: ''
    };

    const [printTemplate, setPrintTemplate] = useState<IPrintTemplate>(initialValues);
    const [openDeleteConfirmationModal, setopenDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        if (props.selectedPrintTemplateId !== 0) {
            agent.PrintTemplates.details(props.selectedPrintTemplateId).then((template) => setPrintTemplate(template))
        } else {
            setPrintTemplate(initialValues);
        }
    }, [props.selectedPrintTemplateId])

    const handleInputChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.currentTarget;
        setPrintTemplate({ ...printTemplate, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            await agent.PrintTemplates.save(printTemplate);
            props.handleRefreshPrintTemplateList();
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {
            toast.error('Se ha producido un error', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handledOpenDeleteConfirmationModal = async () => {
        try {
            if (printTemplate.id !== 0) {
                setopenDeleteConfirmationModal(true);
            }
        } catch {

        }
    }

    const deleteEmailTemplate = async () => {
        await agent.EmailTemplates.delete(printTemplate.id);
        props.handleRefreshPrintTemplateList();
    }

    return (
        <Grid>
            <Grid.Column size={10}>
                <Form onSubmit={handleSubmit}>
                    <Form.Input onChange={handleInputChange}
                        name="nombre"
                        placeholder="Nombre"
                        value={printTemplate.nombre} />
                    <Form.Input onChange={handleInputChange}
                        name="nombreFichero"
                        placeholder="Nombre del fichero"
                        value={printTemplate.nombreFichero} />
                    <Form.Input onChange={handleInputChange}
                        name="prefijoMatricula"
                        placeholder="Prefijo de Matricula"
                        value={printTemplate.prefijoMatricula} />
                    <Form.TextArea onChange={handleInputChange}
                        name="texto"
                        rows={1}
                        placeholder="Texto"
                        value={printTemplate.texto} />
                    <Button
                        floated="right"
                        positive
                        type="submit"
                        content="Guardar"
                    />
                    <Button
                        onClick={props.handleClearSelection}
                        floated="right"
                        type="button"
                        content="Cancelar"
                    />
                    <Button
                        onClick={handledOpenDeleteConfirmationModal}
                        negative
                        floated="left"
                        type="button"
                        content="Eliminar"
                        disabled={props.selectedPrintTemplateId === 0}
                    />
                </Form>
                <DeleteItemModal
                    visible={openDeleteConfirmationModal}
                    message={`¿Desea borrar la plantilla de email ${printTemplate.nombre}?`}
                    onDelete={deleteEmailTemplate}
                    onCancel={props.handleClearSelection}
                    hideDeleteConfirmationModal={() => setopenDeleteConfirmationModal(false)}
                />
            </Grid.Column>
        </Grid>
    )
}

export default PrintTemplatesForm