import { Fragment } from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { IPrintTemplate } from '../../../models/Maintenance';

interface IPrintTemplatesListProps {
    handleOpenEditTemplateForm: (id: number) => void;
    printTemplatesList: IPrintTemplate[];
}

const PrintTemplatesList: React.FC<IPrintTemplatesListProps> = (props) => {
    return (
        <Fragment>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.printTemplatesList.map((printTemplate) => (
                        <Table.Row key={printTemplate.id}>
                            <Table.Cell>{printTemplate.nombre}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    name="edit"
                                    size="large"
                                    className="iconButton"
                                    onClick={() => { props.handleOpenEditTemplateForm(printTemplate.id) }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Fragment>
    )
}

export default PrintTemplatesList