import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import EmailTemplatesList from './EmailTemplatesList'
import EmailTemplateForm from './EmailTemplateForm'
import { IEmailTemplate } from '../../../models/Maintenance'
import agent from '../../../api/agent'

const EmailTemplatesDashboard: React.FC = () => {
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<number>(0)
  const [templatesList, setTemplatesList] = useState<IEmailTemplate[]>([]);

  const refreshEmailTemplateList = () => {
    agent.EmailTemplates.list().then((templates) => setTemplatesList(templates));
  }

  useEffect(refreshEmailTemplateList, [])

  const openEditEmailTemplate = (id: number) => {
    setSelectedEmailTemplateId(id);
  }

  const clearSelection = () => {
    setSelectedEmailTemplateId(0);
  }

  return (
    <Grid>
      <Grid.Column width={6}>
        <EmailTemplatesList
          emailTemplateList={templatesList}
          handleOpenEditTemplateForm={openEditEmailTemplate} />
      </Grid.Column>
      <Grid.Column width={10}>
        <EmailTemplateForm
          selectedEmailTemplateId={selectedEmailTemplateId}
          handleClearSelection={clearSelection}
          handleRefreshEmailTemplateList={refreshEmailTemplateList} />
      </Grid.Column>
    </Grid>
  )
}

export default EmailTemplatesDashboard