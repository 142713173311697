import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

type DeleteItemModalProps = {
    visible: boolean;
    message: string;
    onDelete: () => void;
    onCancel: () => void;
    hideDeleteConfirmationModal: () => void;
};

const DeleteItemModal: React.FC<DeleteItemModalProps> = ({
    visible,
    message,
    onDelete,
    onCancel,
    hideDeleteConfirmationModal
}) => {
    const handleDeleteClick = () => {
        onDelete();
        hideDeleteConfirmationModal();
    };

    const handleCancelClick = () => {
        onCancel();
        hideDeleteConfirmationModal();
    };

    return (
        <Modal open={visible}>
            <Modal.Header>Confirmar borrado</Modal.Header>
            <Modal.Content>
                <p>{message}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={handleDeleteClick}>
                    Borrar
                </Button>
                <Button onClick={handleCancelClick}>Cancelar</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteItemModal;
