import { useRef } from 'react'
import { ISearchAlumnos } from '../../models/Alumno';
import { Button, Grid, Icon, Input } from 'semantic-ui-react';

interface AlumnosSearchProps {
    handleSearch: (searchCriteria: ISearchAlumnos) => void;
    handleSetOpenStatus: (open: boolean) => void;
}

const AlumnosSearch = (props: AlumnosSearchProps) => {
    const nombreRef = useRef(null);
    const dniRef = useRef(null);
    const emailRef = useRef(null);
    const telefonoRef = useRef(null);

    const handleSubmit = () => {
        const searchCriteria: ISearchAlumnos = {
            nombre: nombreRef.current.inputRef.current.value,
            dni: dniRef.current.inputRef.current.value,
            email: emailRef.current.inputRef.current.value,
            telefono: telefonoRef.current.inputRef.current.value,
        };

        props.handleSearch(searchCriteria);
    };

    return (
        <Grid columns={4}>
            <Grid.Row stretched>
                <Grid.Column>
                    <Input label="Nombre Alumno" ref={nombreRef} />
                </Grid.Column>
                <Grid.Column>
                    <Input label="DNI Alumno" ref={dniRef} />
                </Grid.Column>
                <Grid.Column>
                    <Input label="Telefono" ref={telefonoRef} />
                </Grid.Column>
                <Grid.Column>
                    <Input label="Email" ref={emailRef} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} centered>
                <Icon className='iconButton' name='arrow alternate circle up outline' size="big" onClick={() => { props.handleSetOpenStatus(false) }} />
                <Button className='action-button' onClick={handleSubmit}>Buscar</Button>
            </Grid.Row>
        </Grid>
    )
}

export default AlumnosSearch