import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import {
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import CertificadosList from "./CertificadosList";
import CertificadoStore from "../../stores/CertificadosStore";
import CertificadosSearch from "./CertificadosSearch";
import { ISearchCertificados } from "../../models/certificado";
import CertificadoDetails from "./modals/CertificadoDetails";
import CertificadosDashboardActions from "./CertificadosDashboardActions";

const CertificadosDashboard: React.FC = () => {
  const certificadoStore = useContext(CertificadoStore);
  const [openSearch, setOpenSearch] = useState(false);
  const [showCertificadoDetails, setShowCertificadoDetails] = useState(false);

  useEffect(() => {
    const loadSearchData = async () => {
      await certificadoStore.loadSearchData();
    }
    if (!certificadoStore.searchDataLoaded) {
      loadSearchData();
    }
  }, [certificadoStore]);

  const searchCertificados = async (searchCriteria: ISearchCertificados) => {
    await certificadoStore.searchCertificados(searchCriteria);
  };

  return (
    <Grid>
      <Grid.Row centered>
        {!certificadoStore.searchDataLoaded && <Dimmer page active><Loader content='Cargando cuadro de búsqueda...' /></Dimmer>}
        {certificadoStore.searchDataLoaded &&
          <Sidebar
            as={Segment}
            animation="overlay"
            direction="top"
            visible={openSearch}
          >
            <CertificadosSearch
              handleSearch={searchCertificados}
              handleSetOpenStatus={setOpenSearch}
            />
          </Sidebar>
        }
        {(!openSearch) && (
          <Icon
            name="search"
            size="big"
            className="iconButton"
            onClick={() => {
              setOpenSearch(true);
            }}
          />
        )}
      </Grid.Row>
      <Divider horizontal>
        <Header as="h4">Acciones</Header>
      </Divider>
      <CertificadosDashboardActions handleRefresh={certificadoStore.refreshCertificadosList} />
      <Divider horizontal>
        <Header as="h4">Certificados</Header>
      </Divider>
      <Grid.Row>
        <CertificadosList handleShowCertificadoDetails={setShowCertificadoDetails} />
        <CertificadoDetails
          visible={showCertificadoDetails}
          handleVisibleStatus={setShowCertificadoDetails}
        />
      </Grid.Row>
    </Grid>
  );
};

export default observer(CertificadosDashboard);
