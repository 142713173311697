import { observer } from 'mobx-react-lite'
import { IBeneficiosSummary } from '../../models/metricas';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Grid, GridColumn, Header, Table } from 'semantic-ui-react';

interface BeneficiosSummaryProps {
  metricsValues: IBeneficiosSummary[];
}

const BeneficiosSummary: React.FC<BeneficiosSummaryProps> = ({metricsValues}) => {
  return (
    <Grid className='metricsGrid'>
      <Grid.Row centered>
        <Header as='h3'>Cursos Realizados</Header>
      </Grid.Row>
      <Grid.Row>
        <GridColumn width={10}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={metricsValues}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="importe" fill="#8884d8" />
              <Bar dataKey="total" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </GridColumn>
        <GridColumn width={6}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
                <Table.HeaderCell>Cursos Realizados</Table.HeaderCell>
                <Table.HeaderCell>Importe</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {metricsValues.map((value: IBeneficiosSummary) => (
                <Table.Row key={value.date}>
                  <Table.Cell>{value.date}</Table.Cell>
                  <Table.Cell>{value.total}</Table.Cell>
                  <Table.Cell>{value.importe}</Table.Cell>
                </Table.Row>
              ))}
              <Table.Row style={{ backgroundColor: '#f9fafb' }}>
                <Table.Cell>SUMA</Table.Cell>
                <Table.Cell>{metricsValues.reduce((accumulator, currentValue) => { return accumulator + currentValue.total }, 0)}</Table.Cell>
                <Table.Cell>{metricsValues.reduce((accumulator, currentValue) => { return accumulator + currentValue.importe }, 0)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </GridColumn>
      </Grid.Row>
    </Grid>
  )
}

export default observer(BeneficiosSummary)