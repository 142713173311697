import React, { FormEvent, useEffect, useState } from 'react'
import { Button, CheckboxProps, Divider, Form, Grid } from 'semantic-ui-react'
import agent from '../../../api/agent'
import DeleteItemModal from '../ConfirmDeleteModal'
import { toast } from 'react-toastify'
import { IWeb, ICurso } from '../../../models/Maintenance'

interface IWebFormProps {
    selectedWebId: number,
    handleClearSelection: () => void,
    handleRefreshWebsList: () => void
}

const WebForm: React.FC<IWebFormProps> = (props) => {
    const [web, setWeb] = useState({
        id: 0,
        nombre: '',
        url: '',
        dbPrefijoTablas: '',
        dbNombre: '',
        dbUser: '',
        dbPassword: '',
        activa: false,
        smtpServidor: '',
        smtpUser: '',
        smtpPassword: '',
        smtpPort: '',
        smtpUseSsl: false,
        smtpAlias: '',
        nuevaVersionGravity: true,
        cursosIds: []
    } as IWeb);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [initialWebCursosList, setInitialWebCursosList] = useState<number[]>([])
    const [cursosList, setCursosList] = useState<ICurso[]>([])

    useEffect(() => {
        if (props.selectedWebId !== 0) {
            agent.Webs.details(props.selectedWebId).then((selectedWeb) => { setWeb(selectedWeb); setInitialWebCursosList(selectedWeb.cursosIds) });
        } else {
            setWeb({
                id: 0,
                nombre: '',
                url: '',
                dbPrefijoTablas: '',
                dbNombre: '',
                dbUser: '',
                dbPassword: '',
                activa: false,
                smtpServidor: '',
                smtpUser: '',
                smtpPassword: '',
                smtpPort: '',
                smtpUseSsl: false,
                smtpAlias: '',
                nuevaVersionGravity: true,
                cursosIds: []
            } as IWeb);
        }

        agent.Cursos.list().then((cursos) => setCursosList(cursos))
    }, [props.selectedWebId])

    const handleInputChange = (
        event: FormEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.currentTarget;
        setWeb({ ...web, [name]: value });
    };

    const handleCheckboxChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const { name } = event.currentTarget;
        setWeb({ ...web, [name]: data.checked });
    }

    const handleDropdownChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: number[] }
    ) => {
        const removedItems = initialWebCursosList.filter((value) => !data.value.includes(value));

        if (removedItems.length === 0) {
            setWeb({ ...web, cursosIds: data.value });
        }
    };

    const handleSubmit = async () => {
        try {
            await agent.Webs.save(web);
            props.handleRefreshWebsList();
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {

        }
    };

    const handledOpenDeleteConfirmationModal = async () => {
        try {
            if (web.id !== 0) {
                setOpenDeleteConfirmationModal(true);
            }
        } catch {

        }
    }

    const handledCloseDeleteConfirmationModal = async () => {
        setOpenDeleteConfirmationModal(false);
    }

    const deleteWeb = async () => {
        await agent.Webs.delete(web.id);
        props.handleRefreshWebsList();
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid columns={2}>
                <Grid.Row />
                <Divider horizontal>Web</Divider>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="nombre"
                            placeholder="Nombre"
                            label="Nombre"
                            value={web.nombre} />
                        <Form.Checkbox onChange={handleCheckboxChange}
                            name="activa"
                            label="Activa"
                            checked={web.activa} />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="url"
                            placeholder="Url"
                            label="Url"
                            value={web.url} />
                        <Form.Checkbox onChange={handleCheckboxChange}
                            name="nuevaVersionGravity"
                            label="Nueva Version Gravity"
                            checked={web.nuevaVersionGravity} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Form.Select
                            multiple
                            options={cursosList.map(curso => ({ text: curso.nombre, value: curso.id }))}
                            onChange={handleDropdownChange}
                            value={web.cursosIds}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Divider horizontal>Base de datos</Divider>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="dbNombre"
                            placeholder="Nombre de base de datos"
                            label="Nombre"
                            value={web.dbNombre} />
                        <Form.Input onChange={handleInputChange}
                            name="dbUser"
                            placeholder="Nombre de usuario"
                            label="Usuario"
                            value={web.dbUser} />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="dbPrefijoTablas"
                            placeholder="Prefijo de Tablas"
                            label="Prefijo tablas"
                            value={web.dbPrefijoTablas} />
                        <Form.Input onChange={handleInputChange}
                            name="dbPassword"
                            placeholder="Contraseña"
                            label="Contraseña"
                            type="password"
                            value={web.dbPassword} />
                    </Grid.Column>
                </Grid.Row>
                <Divider horizontal>SMTP</Divider>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="smtpServidor"
                            placeholder="Servidor de SMTP"
                            label="Servidor"
                            value={web.smtpServidor} />
                        <Form.Input onChange={handleInputChange}
                            name="smtpUser"
                            placeholder="Usuario"
                            label="Usuario"
                            value={web.smtpUser} />
                        <Form.Input onChange={handleInputChange}
                            name="smtpAlias"
                            placeholder="Alias"
                            label="Alias"
                            value={web.smtpAlias} />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input onChange={handleInputChange}
                            name="smtpPort"
                            placeholder="Puerto"
                            label="Puerto"
                            value={web.smtpPort} />
                        <Form.Input onChange={handleInputChange}
                            name="smtpPassword"
                            placeholder="Contraseña"
                            label="Contraseña"
                            type="password"
                            value={web.smtpPassword} />
                        <Form.Checkbox onChange={handleCheckboxChange}
                            name="smtpUseSsl"
                            label="Usar SSL"
                            checked={web.smtpUseSsl} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Button
                            floated="right"
                            positive
                            type="submit"
                            content="Guardar"
                        />
                        <Button
                            onClick={props.handleClearSelection}
                            floated="right"
                            type="button"
                            content="Cancelar"
                        />
                        <Button
                            onClick={handledOpenDeleteConfirmationModal}
                            negative
                            floated="left"
                            type="button"
                            content="Eliminar"
                            disabled={props.selectedWebId === 0}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <DeleteItemModal
                visible={openDeleteConfirmationModal}
                message={`¿Desea borrar la web ${web.nombre}?`}
                onDelete={deleteWeb}
                onCancel={props.handleClearSelection}
                hideDeleteConfirmationModal={handledCloseDeleteConfirmationModal}
            />
        </Form >
    )
}

export default WebForm