import { Fragment } from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { IWeb } from '../../../models/Maintenance';

interface IWebsListProps {
    handleOpenEditWebForm?: (id: number) => void;
    handleOpenEditWebCursosForm?: (id: number) => void;
    webList: IWeb[];
}

const WebsList: React.FC<IWebsListProps> = (props) => {

    const handleEditButton = (id: number) => {
        if(props.handleOpenEditWebForm){
            props.handleOpenEditWebForm(id);
        }

        if (props.handleOpenEditWebCursosForm) {
            props.handleOpenEditWebCursosForm(id);
        }
    }

    return (
        <Fragment>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.webList.map((web) => (
                        <Table.Row key={web.id}>
                            <Table.Cell>{web.nombre}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    name="edit"
                                    size="large"
                                    className="iconButton"
                                    onClick={() => { handleEditButton(web.id) }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Fragment>
    )
}

export default WebsList