import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import WebsList from './WebsList'
import WebForm from './WebForm'
import agent from '../../../api/agent'
import { IWeb } from '../../../models/Maintenance'

const EmailTemplatesDashboard: React.FC = () => {
  const [selectedWebId, setSelectedWebId] = useState<number>(0)
  const [websList, setWebsList] = useState<IWeb[]>([]);

  const refreshWebsList = () => {
    agent.Webs.list().then((webs) => setWebsList(webs));
  }

  useEffect(refreshWebsList, [])

  const openEditWeb = (id: number) => {
    setSelectedWebId(id);
  }

  const clearSelection = () => {
    setSelectedWebId(0);
  }

  return (
    <Grid>
      <Grid.Column width={6}>
        <WebsList
          webList={websList}
          handleOpenEditWebForm={openEditWeb} />
      </Grid.Column>
      <Grid.Column width={10}>
        <WebForm
          selectedWebId={selectedWebId}
          handleClearSelection={clearSelection}
          handleRefreshWebsList={refreshWebsList} />
      </Grid.Column>
    </Grid>
  )
}

export default EmailTemplatesDashboard