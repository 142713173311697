import { Dimmer, Grid, Icon, Loader, Segment, Sidebar } from 'semantic-ui-react'
import EstadoPagoSummary from './estadoPagoSummary'
import MedioPagoSummary from './medioPagoSummary'
import BeneficiosSummary from './beneficiosSummary'
import CertificadosSearch from '../certificados/CertificadosSearch'
import { useContext, useEffect, useState } from 'react'
import { ISearchCertificados } from '../../models/certificado'
import CertificadoStore from "../../stores/CertificadosStore";
import agent from '../../api/agent'
import { IBeneficiosSummary, IEstadoPagoSummary, IMedioPagoSummary } from '../../models/metricas'
import { observer } from 'mobx-react-lite'

interface IMetricsSummaryData {
  estadoPagoSummary: IEstadoPagoSummary[],
  medioPagoSummary: IMedioPagoSummary[],
  beneficiosSummary: IBeneficiosSummary[]
}

const MetricasDashboard: React.FC = () => {
  const certificadoStore = useContext(CertificadoStore);
  const [openSearch, setOpenSearch] = useState(false);
  const [metricsSummaryData, setMetricsSummaryData] = useState<IMetricsSummaryData>({
    estadoPagoSummary: [],
    medioPagoSummary: [],
    beneficiosSummary: []
  });

  useEffect(() => {
    if (!certificadoStore.searchDataLoaded) {
      const loadSearchData = async () => {
        await certificadoStore.loadSearchData();
      }
      if (!certificadoStore.searchDataLoaded) {
        loadSearchData();
      }
    }
  }, [certificadoStore]);

  const getMetrics = async (searchCriteria: ISearchCertificados) => {
    const estadoPagoData = await agent.Metrics.estadoPago(searchCriteria);
    const medioPagoData = await agent.Metrics.medioPago(searchCriteria);
    const beneficiosData = await agent.Metrics.beneficios(searchCriteria);

    setMetricsSummaryData({
      estadoPagoSummary: estadoPagoData,
      medioPagoSummary: medioPagoData,
      beneficiosSummary: beneficiosData
    });
  };

  return (
    <Grid columns={2} relaxed>
      <Grid.Row centered>
        {!certificadoStore.searchDataLoaded && <Dimmer page active><Loader content='Cargando cuadro de búsqueda...' /></Dimmer>}
        {certificadoStore.searchDataLoaded && (
          <Sidebar
            as={Segment}
            animation="overlay"
            direction="top"
            visible={openSearch}
          >
            <CertificadosSearch
              handleSearch={getMetrics}
              handleSetOpenStatus={setOpenSearch}
            />
          </Sidebar>
        )}
        {!openSearch && (
          <Icon
            name="search"
            size="big"
            className="iconButton"
            onClick={() => {
              setOpenSearch(true);
            }}
          />
        )}
      </Grid.Row>
      <Grid.Row style={{ marginBottom: '20px' }}>
        <Grid.Column>
          <EstadoPagoSummary metricsValues={metricsSummaryData.estadoPagoSummary} />
        </Grid.Column>
        <Grid.Column>
          <MedioPagoSummary metricsValues={metricsSummaryData.medioPagoSummary} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <BeneficiosSummary metricsValues={metricsSummaryData.beneficiosSummary} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default observer(MetricasDashboard)