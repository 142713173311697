import React, { FormEvent, useEffect, useState } from 'react'
import { IEmailTemplate } from '../../../models/Maintenance'
import { Button, Form, Grid } from 'semantic-ui-react'
import agent from '../../../api/agent'
import DeleteItemModal from '../ConfirmDeleteModal'
import { toast } from 'react-toastify'

interface IEmailTemplateFormProps {
    selectedEmailTemplateId: number,
    handleClearSelection: () => void,
    handleRefreshEmailTemplateList: () => void
}

interface TipoPlantillasEmailOptions {
    key: number;
    text: string;
    value: number;
};

const options: TipoPlantillasEmailOptions[] = [
    { key: 1, text: 'Recordatorio de pago', value: 1 },
    { key: 2, text: 'Envío de certificado', value: 2 },
    { key: 3, text: 'Avisos', value: 3 },
];

const EmailTemplateForm: React.FC<IEmailTemplateFormProps> = (props) => {
    const [emailTemplate, setEmailTemplate] = useState({
        id: 0,
        tipoPlantillaEmailId: 0,
        nombre: '',
        alias: '',
        asunto: '',
        mensaje: ''
    } as IEmailTemplate);
    const [openDeleteConfirmationModal, setopenDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        if (props.selectedEmailTemplateId !== 0) {
            agent.EmailTemplates.details(props.selectedEmailTemplateId).then((template) => setEmailTemplate(template))
        } else {
            setEmailTemplate({
                id: 0,
                tipoPlantillaEmailId: 0,
                nombre: '',
                alias: '',
                asunto: '',
                mensaje: ''
            } as IEmailTemplate);
        }
    }, [props.selectedEmailTemplateId])

    const handleInputChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.currentTarget;
        setEmailTemplate({ ...emailTemplate, [name]: value });
    };

    const handleDropdownChange = (
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: { value: number }
    ) => {
        setEmailTemplate({ ...emailTemplate, tipoPlantillaEmailId: data.value });
    };

    const handleSubmit = async () => {
        try {
            await agent.EmailTemplates.save(emailTemplate);
            props.handleRefreshEmailTemplateList();
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {

        }
    };

    const handledOpenDeleteConfirmationModal = async () => {
        try {
            if (emailTemplate.id !== 0) {
                setopenDeleteConfirmationModal(true);
            }
        } catch {

        }
    }

    const handledCloseDeleteConfirmationModal = async () => {
        setopenDeleteConfirmationModal(false);
    }

    const deleteEmailTemplate = async () => {
        await agent.EmailTemplates.delete(emailTemplate.id);
        props.handleRefreshEmailTemplateList();
    }

    return (
        <Grid>
            <Grid.Column size={10}>
                <Form onSubmit={handleSubmit}>
                    <Form.Select
                        options={options}
                        placeholder='Selecciona el tipo de plantilla'
                        onChange={handleDropdownChange}
                        value={emailTemplate.tipoPlantillaEmailId} />
                    <Form.Input onChange={handleInputChange}
                        name="nombre"
                        placeholder="Nombre"
                        value={emailTemplate.nombre} />
                    <Form.Input onChange={handleInputChange}
                        name="alias"
                        placeholder="Alias"
                        value={emailTemplate.alias} />
                    <Form.Input onChange={handleInputChange}
                        name="asunto"
                        placeholder="Asunto"
                        value={emailTemplate.asunto} />
                    <Form.TextArea onChange={handleInputChange}
                        name="mensaje"
                        rows={18}
                        placeholder="Mensaje"
                        value={emailTemplate.mensaje} />
                    <Button
                        floated="right"
                        positive
                        type="submit"
                        content="Guardar"
                    />
                    <Button
                        onClick={props.handleClearSelection}
                        floated="right"
                        type="button"
                        content="Cancelar"
                    />
                    <Button
                        onClick={handledOpenDeleteConfirmationModal}
                        negative
                        floated="left"
                        type="button"
                        content="Eliminar"
                        disabled={props.selectedEmailTemplateId === 0}
                    />
                </Form>
                <DeleteItemModal
                    visible={openDeleteConfirmationModal}
                    message={`¿Desea borrar la plantilla de email ${emailTemplate.nombre}?`}
                    onDelete={deleteEmailTemplate}
                    onCancel={props.handleClearSelection}
                    hideDeleteConfirmationModal={handledCloseDeleteConfirmationModal}
                />
            </Grid.Column>
        </Grid>
    )
}

export default EmailTemplateForm