import { observer } from 'mobx-react-lite'
import { IMedioPagoSummary } from '../../models/metricas';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Grid, GridColumn, Header, Table } from 'semantic-ui-react';

interface MedioPagoSummaryProps {
  metricsValues: IMedioPagoSummary[];
}

const MedioPagoSummary: React.FC<MedioPagoSummaryProps> = ({metricsValues}) => {
  const COLORS = ['#FF5733', '#3399FF', '#9933FF', '#FFA833', '#33FF66', '#FF33A8', '#33FFD8'];

  return (
    <Grid className='metricsGrid'>
      <Grid.Row centered>
        <Header as='h3'>Medios de Pago</Header>
      </Grid.Row>
      <Grid.Row>
        <GridColumn width={6}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={300}>
              <Pie
                data={metricsValues}
                cx="40%"
                cy="50%"
                labelLine={true}
                label
                outerRadius={67}
                fill="#8884d8"
                dataKey="total"
                nameKey="medioPago"
              >
                {metricsValues.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </GridColumn>
        <GridColumn width={10}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Medio de Pago</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {metricsValues.map((value: IMedioPagoSummary) => (
                <Table.Row key={value.medioPago}>
                  <Table.Cell>{value.medioPago}</Table.Cell>
                  <Table.Cell>{value.total}</Table.Cell>
                </Table.Row>
              ))}
              <Table.Row style={{backgroundColor: '#f9fafb'}}>
                <Table.Cell>SUMA</Table.Cell>
                <Table.Cell>{metricsValues.reduce((accumulator, currentValue) => { return accumulator + currentValue.total }, 0)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </GridColumn>
      </Grid.Row>
    </Grid>
  )
}

export default observer(MedioPagoSummary)