import agent from "../api/agent";
import { IChangeEstadoPagoRequest, IPrintExportCertificadosRequest, ISendEmailAlumnoRequestRequest } from "../models/certificado";

export async function setEstadoPagoCertificado(changeEstadoPagorequest: IChangeEstadoPagoRequest) {
    try {
        await agent.Certificados.changeEstadoPago(changeEstadoPagorequest);
    }
    catch (error) {
        console.log(`Error setting new estado de pago`, error);
    }
}

export async function sendCertificadosToPrinter(printCertificadosRequest: IPrintExportCertificadosRequest) {
    try {
        await agent.Certificados.printCertificados(printCertificadosRequest);
    }
    catch (error) {
        console.log(`Error sending certificados to printer queue`, error);
    }
}

export async function sendEtiquetasToPrinter(printCertificadosRequest: IPrintExportCertificadosRequest) {
    try {
        await agent.Certificados.printEtiquetas(printCertificadosRequest);
    }
    catch (error) {
        console.log(`Error sending etiquetas to printer queue`, error);
    }
}

export async function exportCertificados(printCertificadosRequest: IPrintExportCertificadosRequest) {
    try {
        await agent.Certificados.exportCertificados(printCertificadosRequest);
    }
    catch (error) {
        console.log(`Error sending certificados to export queue`, error);
    }
}

export async function sendRecordatorioEmail(sendRecordatorioEmailRequest: ISendEmailAlumnoRequestRequest) {
    try {
        await agent.Certificados.sendRecordatorio(sendRecordatorioEmailRequest);
    }
    catch (error) {
        console.log(`Error sending recordatorio email to queue`, error);
    }
}

export async function sendCertificadoEmail(sendCertificadoEmailRequest: ISendEmailAlumnoRequestRequest) {
    try {
        await agent.Certificados.sendCertificadoEmail(sendCertificadoEmailRequest);
    }
    catch (error) {
        console.log(`Error sending certificado email to queue`, error);
    }
}