import React, { FormEvent, useState } from 'react'
import { Button, Checkbox, CheckboxProps, Form, Grid, Segment } from 'semantic-ui-react'
import { IWebCurso } from '../../../models/Maintenance';
import { toast } from 'react-toastify';
import DeleteItemModal from '../ConfirmDeleteModal';
import agent from '../../../api/agent';
import WebCursosMapeosFormModal from './WebCursosMapeosFormModal';
import WebCursosPlantillasImpresionFormModal from './WebCursosPlantillasImpresionFormModal';

interface IWebCursoForm {
    webCurso: IWebCurso;
    handleRefreshWebCursosList: () => void
}

const WebCursosForm: React.FC<IWebCursoForm> = (props) => {
    const [webCursoToUpdate, setWebCursoToUpdate] = useState({
        id: props.webCurso.id,
        webId: props.webCurso.webId,
        cursoId: props.webCurso.cursoId,
        nombre: props.webCurso.nombre,
        gravityFormId: props.webCurso.gravityFormId,
        activo: props.webCurso.activo
    } as IWebCurso);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [openMapeosFormModal, setOpenMapeosFormModal] = useState(false);
    const [openPlantillasFormModal, setOpenPlantillasFormModal] = useState(false);

    const resetWebCurso = () => {
        setWebCursoToUpdate({
            id: props.webCurso.id,
            webId: props.webCurso.webId,
            cursoId: props.webCurso.cursoId,
            nombre: props.webCurso.nombre,
            gravityFormId: props.webCurso.gravityFormId,
            activo: props.webCurso.activo
        } as IWebCurso);
    }

    const handleSubmit = async () => {
        try {
            await agent.WebCursos.save(webCursoToUpdate);
            props.handleRefreshWebCursosList();
            toast.success('Cambios guardados correctamente', {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch {
            toast.error('Se ha producido un error', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const handleInputChange = (
        event: FormEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.currentTarget;
        setWebCursoToUpdate({ ...webCursoToUpdate, [name]: value });
    };

    const handleCheckboxChange = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        setWebCursoToUpdate({ ...webCursoToUpdate, [data.name]: data.checked });
    }

    const handledOpenDeleteConfirmationModal = async () => {
        try {
            if (props.webCurso.id !== 0) {
                setOpenDeleteConfirmationModal(true);
            }
        } catch {

        }
    }

    const handledCloseDeleteConfirmationModal = async () => {
        setOpenDeleteConfirmationModal(false);
    }

    const deleteWebCurso = async () => {
        await agent.WebCursos.delete(props.webCurso.id);
        props.handleRefreshWebCursosList();
        toast.success('Cambios guardados correctamente', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    return (
        <Segment clearing size='tiny' >
            <Form onSubmit={handleSubmit}>
                <Grid columns={2}>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            {webCursoToUpdate.nombre}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                        <Grid.Column>
                            <Form.Input onChange={handleInputChange}
                                name="gravityFormId"
                                placeholder="Gravity Form ID"
                                label="Gravity Form ID"
                                style={{ width: "60%" }}
                                value={webCursoToUpdate.gravityFormId} />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox
                                toggle
                                name='activo'
                                label="Activo"
                                onChange={handleCheckboxChange}
                                defaultChecked={webCursoToUpdate.activo}
                            />
                        </Grid.Column>
                        <Grid.Column>
                        <Button
                                onClick={() => setOpenPlantillasFormModal(true)}
                                floated="right"
                                type="button"
                                content="Plantillas"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                onClick={() => setOpenMapeosFormModal(true)}
                                floated="right"
                                type="button"
                                content="Mapeos"
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Button
                                floated="right"
                                positive
                                type="submit"
                                content="Guardar"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                onClick={resetWebCurso}
                                floated="right"
                                type="button"
                                content="Cancelar"
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                onClick={handledOpenDeleteConfirmationModal}
                                negative
                                floated="left"
                                type="button"
                                content="Eliminar"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <DeleteItemModal
                    visible={openDeleteConfirmationModal}
                    message={`¿Desea borrar el curso seleccionado?`}
                    onDelete={deleteWebCurso}
                    onCancel={resetWebCurso}
                    hideDeleteConfirmationModal={handledCloseDeleteConfirmationModal}
                />
                <WebCursosMapeosFormModal selectedWebCursoId={props.webCurso.id} visible={openMapeosFormModal} handleVisibleStatus={setOpenMapeosFormModal} />
                <WebCursosPlantillasImpresionFormModal selectedWebCursoId={props.webCurso.id} visible={openPlantillasFormModal} handleVisibleStatus={setOpenPlantillasFormModal} />
            </Form>
        </Segment>
    )
}

export default WebCursosForm;