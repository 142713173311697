import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import CursosList from './CursosList'
import CursoForm from './CursosForm'
import agent from '../../../api/agent'
import { ICurso } from '../../../models/Maintenance'

const EmailTemplatesDashboard: React.FC = () => {
  const [selectedCursoId, setSelectedCursoId] = useState<number>(0)
  const [cursosList, setCursosList] = useState<ICurso[]>([]);

  const refreshWebsList = () => {
    agent.Cursos.list().then((cursos) => setCursosList(cursos));
  }

  useEffect(refreshWebsList, [])

  const openEditCurso
    = (id: number) => {
      setSelectedCursoId(id);
    }

  const clearSelection = () => {
    setSelectedCursoId(0);
  }

  return (
    <Grid>
      <Grid.Column width={6}>
        <CursosList
          cursosList={cursosList}
          handleOpenEditCursoForm={openEditCurso} />
      </Grid.Column>
      <Grid.Column width={10}>
        <CursoForm
          selectedCursoId={selectedCursoId}
          handleClearSelection={clearSelection}
          handleRefreshCursosList={refreshWebsList}
          cursosList={cursosList} />
      </Grid.Column>
    </Grid>
  )
}

export default EmailTemplatesDashboard