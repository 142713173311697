import { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { IPrintTemplate } from '../../../models/Maintenance'
import agent from '../../../api/agent'
import PrintTemplatesList from './PrintTemplatesList'
import PrintTemplatesForm from './PrintTemplatesForm'

const PrintTemplatesDashboard: React.FC = () => {
  const [selectedPrintTemplateId, setSelectedPrintTemplateId] = useState<number>(0)
  const [templatesList, setTemplatesList] = useState<IPrintTemplate[]>([]);

  const refreshEmailTemplateList = () => {
    agent.PrintTemplates.list().then((templates) => setTemplatesList(templates));
  }

  useEffect(refreshEmailTemplateList, [])

  const openEditEmailTemplate = (id: number) => {
    setSelectedPrintTemplateId(id);
  }

  return (
    <Grid>
      <Grid.Column width={6}>
        <PrintTemplatesList
          printTemplatesList={templatesList}
          handleOpenEditTemplateForm={openEditEmailTemplate} />
      </Grid.Column>
      <Grid.Column width={10}>
        <PrintTemplatesForm
          selectedPrintTemplateId={selectedPrintTemplateId}
          handleClearSelection={() => setSelectedPrintTemplateId(0)}
          handleRefreshPrintTemplateList={refreshEmailTemplateList} />
      </Grid.Column>
    </Grid>
  )
}

export default PrintTemplatesDashboard