import {
  action,
  makeObservable,
  observable,
  computed,
  configure,
  runInAction,
} from "mobx";
import { createContext } from "react";
import agent from "../api/agent";
import { IAlumno, ISearchAlumnos } from "../models/Alumno";

configure({ enforceActions: "always" });

class CertificadoStore {
  @observable searchCriteria: ISearchAlumnos | null = null;
  @observable alumnosList: IAlumno[] = [];
  @observable selectedAlumnoId: number | null = null;
  
  constructor() {
    makeObservable(this);
  }

  @computed get listaCertificados() {
    return this.alumnosList.values;
  }

  @action setSearchAlumnosCriteria = (searchCriteria: ISearchAlumnos) => {
    this.searchCriteria = searchCriteria;
  };

  @action searchAlumnos = async (searchCriteria: ISearchAlumnos) => {
    try {
      if (searchCriteria === null) searchCriteria = {} as ISearchAlumnos;

      const alumnos = await agent.Alumnos.search(searchCriteria);
      runInAction(() => {
        this.alumnosList = alumnos;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action setSelectedAlumnoId = (alumnoId: number) => {
    this.selectedAlumnoId = alumnoId;
  };
}

export default createContext(new CertificadoStore());
