import { useMsal } from "@azure/msal-react";
import { Button, Container, Header, Segment, Image } from "semantic-ui-react";
import { loginRequest } from "../authentication/msal";

export const HomePage = () => {
  const { instance, accounts } = useMsal();

  const handleLogin = async () => {
    if (accounts.length === 0) {
      instance.loginRedirect(loginRequest).catch((error) => console.log(error));    }
  };

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Header as="h1" inverted>
          <Image
            size="massive"
            src="/assets/logo.png"
            alt="logo"
            style={{ marginBottom: 12 }}
          />
          Laborali
        </Header>
        <Button onClick={handleLogin} size="huge" inverted>
          Log In
        </Button>
      </Container>
    </Segment>
  );
};
