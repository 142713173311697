import React from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { IComparativaFechas } from '../../models/metricas';
import { Divider, Grid, Header } from 'semantic-ui-react';

interface ComparativaChartProps {
  comparativaData: IComparativaFechas[];
}

const ComparativaChart: React.FC<ComparativaChartProps> = ({ comparativaData }) => {
  const COLORS = ['#FF5733', '#3399FF', '#9933FF', '#FFA833', '#33FF66', '#FF33A8', '#33FFD8'];

  return (
    <>
      <Grid className='metricsGrid'>
        <Grid.Row centered>
          <Header as='h3'>Importe</Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ResponsiveContainer width="100%" height="100%" aspect={5}>
              <LineChart width={500} height={300}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" type="category" allowDuplicatedCategory={false} />
                <YAxis dataKey="importe" />
                <Tooltip />
                <Legend />
                {comparativaData.map((s, index) => (
                  <Line dataKey="importe" data={s.beneficios} name={s.dateRange} key={s.dateRange} stroke={COLORS[index % COLORS.length]} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider clearing section/>
      <Grid className='metricsGrid'>
        <Grid.Row centered>
          <Header as='h3'>Cursos</Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ResponsiveContainer width="100%" height="100%" aspect={5}>
              <LineChart width={500} height={300}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" type="category" allowDuplicatedCategory={false} />
                <YAxis dataKey="total" />
                <Tooltip />
                <Legend />
                {comparativaData.map((s, index) => (
                  <Line dataKey="total" data={s.beneficios} name={s.dateRange} key={s.dateRange} stroke={COLORS[index % COLORS.length]} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default ComparativaChart