import React, { Fragment } from 'react'
import { ICertificado } from '../../models/certificado'
import { Icon, Popup } from 'semantic-ui-react'

interface CertificadosListIconsProps {
    certificado: ICertificado
}

const CertificadosListIcons = (props: CertificadosListIconsProps) => {
    const certificado = props.certificado;
    return (
        <Fragment>
            <Popup
                trigger={
                    <Icon
                        name="emergency"
                        size="large"
                        color="red"
                        style={{
                            visibility: certificado.envioUrgente
                                ? "visible"
                                : "hidden",
                        }}
                    />
                }
                content="Envio Urgente Solicitado"
            />
            <Popup
                trigger={
                    <Icon
                        name="envelope outline"
                        size="large"
                        color={certificado.mailEnviado ? "green" : "grey"}
                    />
                }
                content={
                    certificado.mailEnviado
                        ? "Certificado Enviado por Email"
                        : "Pendiente Envio Por Email"
                }
            />
            <Popup
                trigger={
                    <Icon
                        name="home"
                        size="large"
                        color={certificado.postalEnviado ? "green" : "grey"}
                    />
                }
                content={
                    certificado.postalEnviado
                        ? "Correo Postal Enviado"
                        : "Pendiente Envio Por Correo Postal"
                }
            />
            <Popup
                trigger={
                    <Icon
                        name="comment alternate outline"
                        size="large"
                        color={certificado.alerta ? "green" : "red"}
                    />
                }
                content="Hay comentario de Cliente"
            />
            <Popup
                trigger={
                    <Icon
                        name="euro"
                        size="large"
                        color="red"
                        style={{
                            visibility: certificado.recordatorioPagoEnviado
                                ? "visible"
                                : "hidden",
                        }}
                    />
                }
                content="Recordatorio de Pago Enviado"
            />
        </Fragment>
    )
}

export default CertificadosListIcons