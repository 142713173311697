import { observer } from 'mobx-react-lite'
import { useContext, useState } from "react";
import {
    Divider,
    Grid,
    Header,
    Icon,
    Segment,
    Sidebar,
} from "semantic-ui-react";
import { ISearchAlumnos } from '../../models/Alumno';
import AlumnosSearch from './AlumnosSearch';
import AlumnosList from './AlumnosList';
import AlumnosStore from '../../stores/AlumnosStore';
import CommonStore from '../../stores/CommonStore';

const AlumnosDashboard = () => {
    const [openSearch, setOpenSearch] = useState(false);
    const alumnosStore = useContext(AlumnosStore);
    const commonStore = useContext(CommonStore);

    const searchAlumnos = async (searchCriteria: ISearchAlumnos) => {
        commonStore.setLoadingStatus(true);
        await alumnosStore.searchAlumnos(searchCriteria);
        commonStore.setLoadingStatus(false);
    };

    return (
        <Grid>
            <Grid.Row centered>
                <Sidebar
                    as={Segment}
                    animation="overlay"
                    direction="top"
                    visible={openSearch}
                >
                    <AlumnosSearch
                        handleSearch={searchAlumnos}
                        handleSetOpenStatus={setOpenSearch}
                    />
                </Sidebar>
                {!openSearch && (
                    <Icon
                        className='iconButton'
                        name="search"
                        size="big"
                        onClick={() => {
                            setOpenSearch(true);
                        }}
                    />
                )}
            </Grid.Row>
            <Divider horizontal>
                <Header as="h4">Alumnos</Header>
            </Divider>
            <Grid.Row>
                <AlumnosList />
            </Grid.Row>
        </Grid>
    );
}

export default observer(AlumnosDashboard)