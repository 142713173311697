import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Grid, Modal, Table } from 'semantic-ui-react';
import agent from '../../../api/agent';
import { ICertificado } from '../../../models/certificado';
import AlumnosStore from '../../../stores/AlumnosStore';
import { getDatePresentationValue } from '../../../functions/CommonFunctions';
import CertificadosListIcons from '../../certificados/CertificadosListIcons';
import CertificadoDetails from '../../certificados/modals/CertificadoDetails';
import CertificadosDashboardActions from '../../certificados/CertificadosDashboardActions';
import CertificadosStore from '../../../stores/CertificadosStore';
import { observer } from 'mobx-react-lite';

export interface IAlumnoCertificadosListModallProps {
  visible: boolean;
  handleVisibleStatus: (open: boolean) => void;
}

const AlumnoCertificadosListModal: React.FC<IAlumnoCertificadosListModallProps> = (props) => {
  const [certificados, setCertificados] = useState([] as ICertificado[])
  const [showCertificadoDetails, setShowCertificadoDetails] = useState(false);

  const alumnosStore = useContext(AlumnosStore);
  const {
    selectedAlumnoId,
  } = alumnosStore;

  const certificadoStore = useContext(CertificadosStore);
  const {
    certificadosSelectedList,
    setSelectedCertificados,
    clearCertificadosSelectedList
  } = certificadoStore;

  const refreshCertificadosList = async () => {
    var certificados = await agent.Alumnos.certificadosAlumno(selectedAlumnoId);
    setCertificados(certificados);
  }

  useEffect(() => {
    if (selectedAlumnoId) {
      agent.Alumnos.certificadosAlumno(selectedAlumnoId).then(certificadosAlumno => setCertificados(certificadosAlumno));
    }
  }, [selectedAlumnoId]);

  const closeModal = () => {
    clearCertificadosSelectedList();
    props.handleVisibleStatus(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { clearCertificadosSelectedList() }, [])

  if (!selectedAlumnoId) return;

  return (
    <Modal open={props.visible} size="fullscreen">
      <Modal.Header>Certificados</Modal.Header>
      <Modal.Content>
        <Grid>
          <CertificadosDashboardActions handleRefresh={refreshCertificadosList} />
        </Grid>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Alumno</Table.HeaderCell>
              <Table.HeaderCell>Dni</Table.HeaderCell>
              <Table.HeaderCell>Curso</Table.HeaderCell>
              <Table.HeaderCell>Web</Table.HeaderCell>
              <Table.HeaderCell>Medio de Pago</Table.HeaderCell>
              <Table.HeaderCell>Estado de Pago</Table.HeaderCell>
              <Table.HeaderCell>Descuento</Table.HeaderCell>
              <Table.HeaderCell>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Fecha de Pago</Table.HeaderCell>
              <Table.HeaderCell>Importe</Table.HeaderCell>
              <Table.HeaderCell>Importe Final</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {certificados.map((certificado: ICertificado) => (
              <Table.Row key={certificado.id}>
                <Table.Cell style={{ display: "flex", gap: "2px" }}>
                  <CertificadosListIcons certificado={certificado} />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    value={certificado.id}
                    checked={certificadosSelectedList.includes(certificado.id)}
                    onChange={() => setSelectedCertificados(certificado.id)}
                  />
                </Table.Cell>
                <Table.Cell>{certificado.nombreAlumno}</Table.Cell>
                <Table.Cell>{certificado.dniAlumno}</Table.Cell>
                <Table.Cell>{certificado.curso}</Table.Cell>
                <Table.Cell>{certificado.web}</Table.Cell>
                <Table.Cell>{certificado.medioPago}</Table.Cell>
                <Table.Cell>{certificado.estadoPago}</Table.Cell>
                <Table.Cell>{certificado.codigoDescuento}</Table.Cell>
                <Table.Cell>
                  {getDatePresentationValue(certificado.fecha)}
                </Table.Cell>
                <Table.Cell>
                  {getDatePresentationValue(certificado.fechaPago)}
                </Table.Cell>
                <Table.Cell>{`${certificado.importe} €`}</Table.Cell>
                <Table.Cell>{`${certificado.importeFinal} €`}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => closeModal()}>
          Cerrar
        </Button>
      </Modal.Actions>
      <CertificadoDetails
        visible={showCertificadoDetails}
        handleVisibleStatus={setShowCertificadoDetails}
      />
    </Modal>
  )
}

export default observer(AlumnoCertificadosListModal)