import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Divider, Grid, Icon, Loader, Segment, Sidebar } from 'semantic-ui-react';
import { ISearchCertificados } from '../../models/certificado';
import agent from '../../api/agent';
import { observer } from 'mobx-react-lite'
import CertificadoStore from "../../stores/CertificadosStore";
import CertificadosSearch from '../certificados/CertificadosSearch';
import { IComparativaFechas } from '../../models/metricas';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { getDatePickerDefaultDates, getFilterDate } from '../../functions/CommonFunctions';
import ComparativaChart from './ComparativaChart';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';

const ComparativaDashboard: React.FC = () => {
    const dateRangeRef = useRef(null);
    const certificadoStore = useContext(CertificadoStore);
    const [openSearch, setOpenSearch] = useState(false);
    const [datesToCompare, setdatesToCompare] = useState(null);
    const [comparativaData, setComparativaData] = useState<IComparativaFechas[]>([]);

    useEffect(() => {
        if (!certificadoStore.searchDataLoaded) {
            const loadSearchData = async () => {
                await certificadoStore.loadSearchData();
            }
            if (!certificadoStore.searchDataLoaded) {
                loadSearchData();
            }
        }
    }, [certificadoStore]);

    const getMetrics = async (searchCriteria: ISearchCertificados) => {
        let startDateToCompare = getFilterDate(
            dateRangeRef.current.inputRef.current.inputRef.current.value,
            true);

        let endDateToCompare = getFilterDate(
            dateRangeRef.current.inputRef.current.inputRef.current.value,
            false);

        const result = await agent.Metrics.comparativa(searchCriteria, startDateToCompare, endDateToCompare);

        setComparativaData(result);
    };

    const getInitialDates = (): Date[] => {
        if (datesToCompare !== null)
            return datesToCompare;

        let initialDate = getDatePickerDefaultDates();
        return initialDate;
    }

    const handleDateRangeChange = (data: SemanticDatepickerProps) => {
        console.log(data.value.toString().split("-").length);
        setdatesToCompare(data.value);
    }

    return (
        <Grid relaxed>
            <Grid.Row centered>
                {!certificadoStore.searchDataLoaded && <Dimmer page active><Loader content='Cargando cuadro de búsqueda...' /></Dimmer>}
                {certificadoStore.searchDataLoaded && (
                    <Sidebar
                        as={Segment}
                        animation="overlay"
                        direction="top"
                        visible={openSearch}
                    >
                        <CertificadosSearch
                            handleSearch={getMetrics}
                            handleSetOpenStatus={setOpenSearch}
                        />
                    </Sidebar>
                )}
                {!openSearch && (
                    <Icon
                        name="search"
                        size="big"
                        className="iconButton"
                        onClick={() => {
                            setOpenSearch(true);
                        }}
                    />
                )}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className="ui labeled input" style={{marginRight: '1em'}}>
                        <div className="ui label label">Fecha a comparar</div>
                        <SemanticDatepicker
                            className="DatePicker"
                            placeholder="DD/MM/YYYY - DD/MM/YYYY"
                            format="DD/MM/YYYY"
                            locale="es-ES"
                            ref={dateRangeRef}
                            type="range"
                            datePickerOnly
                            value={getInitialDates()}
                            onChange={(e, data) => handleDateRangeChange(data)}
                        />
                    </div>
                    <Divider vertical/>
                    <Button 
                        className='action-button' 
                        onClick={() => getMetrics(certificadoStore.searchCriteria)}
                        >Actualizar</Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <ComparativaChart comparativaData={comparativaData} />
                </Grid.Column>
            </Grid.Row>
        </Grid >
    )
}

export default observer(ComparativaDashboard)