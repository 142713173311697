import {
  action,
  makeObservable,
  observable,
  computed,
  configure,
  runInAction,
} from "mobx";
import { createContext } from "react";
import agent from "../api/agent";
import {
  ICertificado,
  ICertificadoDetails,
  ISearchCertificados,
} from "../models/certificado";
import { IDropdownOption } from "../models/dropdownOptions";

configure({ enforceActions: "always" });

class CertificadoStore {
  @observable searchCriteria: ISearchCertificados | null = null;
  @observable certificadosList: ICertificado[] = [];
  @observable certificado: ICertificadoDetails | null = null;
  @observable certificadosSelectedList: number[] = [];
  @observable searchDataLoaded = false;
  @observable estadosPagoList: any = [];
  @observable cursosList: IDropdownOption[] = [];
  @observable websList: IDropdownOption[] = [];
  @observable emailTemplatesList: any = [];

  constructor() {
    makeObservable(this);
  }

  @computed get listaCertificados() {
    return this.certificadosList.values;
  }

  @action setSearchCertificadosCriteria = (searchCriteria: ISearchCertificados) => {
    this.searchCriteria = searchCriteria;
  };

  @action searchCertificados = async (searchCriteria: ISearchCertificados) => {
    try {
      if (searchCriteria === null) searchCriteria = {} as ISearchCertificados;

      const certificados = await agent.Certificados.search(searchCriteria);
      runInAction(() => {
        this.certificadosList = certificados;
        this.certificadosSelectedList = [];
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action refreshCertificadosList = async () => {
    await this.searchCertificados(this.searchCriteria);
  };

  @action loadSearchData = async () => {
    const estadoPagoList = await agent.Desplegables.estadosPago();
    const cursosList = await agent.Desplegables.cursos();
    const websList = await agent.Desplegables.webs();
    const emailTemplatesList = await agent.Desplegables.emailTemplates();

    runInAction(() => {
      this.estadosPagoList = estadoPagoList;
      this.cursosList = cursosList;
      this.websList = websList;
      this.emailTemplatesList = emailTemplatesList;
      this.searchDataLoaded = true;
    });
  };

  @action loadcertificado = async (id: number) => {
    try {
      let certificado = await agent.Certificados.details(id);
      runInAction(() => {
        this.certificado = certificado;
      });
    } catch (error) {
      console.log(error);
    };
  };

  @action setSelectedCertificados = (id: number) => {
    if (this.certificadosSelectedList.includes(id)) {
      this.certificadosSelectedList.splice(
        this.certificadosSelectedList.indexOf(id, 0),
        1
      );
    } else {
      this.certificadosSelectedList.push(id);
    }
  };

  @action clearcertificado = () => {
    this.certificado = null;
  };

  @action clearCertificadosSelectedList = () => {
    this.certificadosSelectedList = [];
  };
}

export default createContext(new CertificadoStore());
