import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Dropdown, Grid, Modal } from 'semantic-ui-react';
import agent from '../../../api/agent';
import { setEstadoPagoCertificado } from '../../../functions/CertificadosFunctions';
import { IChangeEstadoPagoRequest } from '../../../models/certificado';
import certificadosStore from '../../../stores/CertificadosStore';
import { IModallProps } from './models/ModalProps';
import CommonStore from '../../../stores/CommonStore';
import { showErrorToast, showSuccessToast } from '../../../functions/CommonFunctions';

const ChangeEstadoPagoModal: React.FC<IModallProps> = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const [estadosPagoList, setEstadosPagoList] = useState(null);
    const dateRangeRef = useRef(null);
    const estadoPagoRef = useRef(null);

    const certificadoStore = useContext(certificadosStore);
    const commonStore = useContext(CommonStore);

    const { certificadosSelectedList } = certificadoStore;

    useEffect(() => {
        Promise.all([
            agent.Desplegables.estadosPago()
        ]).then(([estadoPagoList]) => {
            setEstadosPagoList(estadoPagoList);
        });
    }, []);

    const cambiarEstadoPago = async () => {
        const cambioEstadoPagoRequest: IChangeEstadoPagoRequest = {
            ids: certificadosSelectedList,
            idEstadoPago: estadoPagoRef.current.state.value,
            fechaPago: getFechaDePago()
        };

        try {
            setSubmitting(true);
            commonStore.setLoadingStatus(true);
            await setEstadoPagoCertificado(cambioEstadoPagoRequest);
            await props.handleRefresh();
            showSuccessToast();
        }
        catch {
            showErrorToast();
        }
        setSubmitting(false);
        props.handleVisibleStatus(false);
        commonStore.setLoadingStatus(false);
    }

    const getFechaDePago = () => {
        let rawDate = dateRangeRef.current.inputRef.current.inputRef.current.value;

        if(rawDate === "")
            return null;

        let [day, month, year] = rawDate.split("/");
        let selectedDate = `${year}-${month}-${day}`;
        return selectedDate;
    }

    return (
        <Modal open={props.visible} size='small'>
            <Modal.Header>Cambiar Estado de Pago</Modal.Header>
            <Modal.Content>
                <Grid columns={2}>
                    <Grid.Column>
                        <div className="ui labeled input">
                            <div className="ui label label">Fechas</div>
                            <SemanticDatepicker
                                className="DatePicker"
                                placeholder="DD/MM/YYYY"
                                format="DD/MM/YYYY"
                                locale="es-ES"
                                ref={dateRangeRef}
                                type="basic"
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className="ui labeled input">
                            <div className="ui label label">Estado de Pago</div>
                            <Dropdown
                                selection
                                options={estadosPagoList}
                                ref={estadoPagoRef}
                                defaultValue={1}
                            />
                        </div>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => props.handleVisibleStatus(false)}>
                    Cancelar
                </Button>
                <Button positive onClick={() => cambiarEstadoPago()} disabled={submitting}>
                    Aceptar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default observer(ChangeEstadoPagoModal);