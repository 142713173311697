import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import {
    Dropdown,
    Icon,
    Pagination,
    Table,
} from "semantic-ui-react";
import { getDatePresentationValue, getSortConfig } from "../../functions/CommonFunctions";
import { IAlumno } from "../../models/Alumno";
import AlumnosStore from "../../stores/AlumnosStore";
import AlumnoCertificadosListModal from "./modals/AlumnoCertificadosListModal";
import CommonStore from "../../stores/CommonStore";
import { ISortConfig } from "../../models/Common";

const AlumnosList: React.FC = () => {
    const [showAlumnoCertificados, setShowAlumnoCertificados] = useState(false);
    const commonStore = useContext(CommonStore);
    const certificadoStore = useContext(AlumnosStore);
    const {
        alumnosList,
        setSelectedAlumnoId
    } = certificadoStore;

    const openAlumnoCursosList = async (alumnoId: number) => {
        commonStore.setLoadingStatus(true);
        setSelectedAlumnoId(alumnoId);
        setShowAlumnoCertificados(true);
        commonStore.setLoadingStatus(false);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [sortConfig, setSortConfig] = useState<ISortConfig>({
        key: null,
        direction: null,
    });

    const totalPages = Math.ceil(alumnosList.length / pageSize);
    const indexOfLastItem = Number(currentPage) * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;

    const getCurrentItemListSorted = (): IAlumno[] => {
        const key = sortConfig.key;
        const sortedData = alumnosList.slice().sort((a, b) => {
            if (sortConfig.direction === 'ascending') {
                return a[key as keyof IAlumno] > b[key as keyof IAlumno] ? 1 : -1;
            } else {
                return a[key as keyof IAlumno] < b[key as keyof IAlumno] ? 1 : -1;
            }
        });

        return sortedData.slice(indexOfFirstItem, indexOfLastItem);
    };

    const currentItems = getCurrentItemListSorted();

    const handleSort = (clickedColumn: string) => {
        const newSortConfig = getSortConfig(clickedColumn, { ...sortConfig });
        setSortConfig(newSortConfig);
    };

    return (
        <Fragment>
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell sorted={sortConfig.key === 'nombre' ? sortConfig.direction : null} onClick={() => handleSort('nombre')}>Nombre</Table.HeaderCell>
                        <Table.HeaderCell sorted={sortConfig.key === 'dni' ? sortConfig.direction : null} onClick={() => handleSort('dni')}>Dni</Table.HeaderCell>
                        <Table.HeaderCell sorted={sortConfig.key === 'email' ? sortConfig.direction : null} onClick={() => handleSort('email')}>Email</Table.HeaderCell>
                        <Table.HeaderCell sorted={sortConfig.key === 'telefono' ? sortConfig.direction : null} onClick={() => handleSort('telefono')}>Telefono</Table.HeaderCell>
                        <Table.HeaderCell sorted={sortConfig.key === 'emailAlternativo' ? sortConfig.direction : null} onClick={() => handleSort('emailAlternativo')}>Email Alternativo</Table.HeaderCell>
                        <Table.HeaderCell sorted={sortConfig.key === 'fechaAlta' ? sortConfig.direction : null} onClick={() => handleSort('fechaAlta')}>Fecha de Alta</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {currentItems.map((alumno: IAlumno) => (
                        <Table.Row key={alumno.id}>
                            <Table.Cell>{alumno.nombre}</Table.Cell>
                            <Table.Cell>{alumno.dni}</Table.Cell>
                            <Table.Cell>{alumno.email}</Table.Cell>
                            <Table.Cell>{alumno.telefono}</Table.Cell>
                            <Table.Cell>{alumno.emailAlternativo}</Table.Cell>
                            <Table.Cell>{getDatePresentationValue(alumno.fechaAlta)}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    className="iconButton"
                                    name="eye"
                                    size="large"
                                    onClick={() => { openAlumnoCursosList(alumno.id) }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination
                activePage={currentPage}
                onPageChange={(e, { activePage }) => setCurrentPage(activePage as number)}
                totalPages={totalPages}
                boundaryRange={0}
                siblingRange={1}
                ellipsisItem={null}
                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
            />
            <div className="ui labeled input">
                <div className="ui label label">Elementos por página</div>
                <Dropdown
                    selection
                    options={[
                        { text: "50", value: 50 },
                        { text: "100", value: 100 },
                        { text: "200", value: 200 },
                    ]}
                    defaultValue={pageSize}
                    onChange={(e, data) => setPageSize(data.value as number)}
                />
            </div>
            <AlumnoCertificadosListModal
                visible={showAlumnoCertificados}
                handleVisibleStatus={setShowAlumnoCertificados}
            />
        </Fragment>
    )
}

export default observer(AlumnosList)