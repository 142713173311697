import { useContext, useRef } from "react";
import { Button, Checkbox, Dropdown, Grid, Icon, Input } from "semantic-ui-react";
import { ISearchCertificados } from "../../models/certificado";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import certificadosStore from "../../stores/CertificadosStore";
import { observer } from "mobx-react-lite";
import CommonStore from "../../stores/CommonStore";
import { getDatePickerDefaultDates, getFilterDate } from "../../functions/CommonFunctions";
import { EstadosPagoDropdown, EstadosPagoPaid, EstadosPagoPending, EstadosPagoRejected, MediosPagoDropdown } from "../../constants";

interface CertificadosSearchProps {
  handleSearch: (searchCriteria: ISearchCertificados) => Promise<void>;
  handleSetOpenStatus: (open: boolean) => void;
}

const CertificadosSearch: React.FC<CertificadosSearchProps> = (props) => {
  const nombreRef = useRef(null);
  const dniRef = useRef(null);
  const emailRef = useRef(null);
  const telefonoRef = useRef(null);
  const descuentoRef = useRef(null);
  const dateRangeRef = useRef(null);
  const medioPagoRef = useRef(null);
  const estadoPagoRef = useRef(null);
  const websRef = useRef(null);
  const cursosRef = useRef(null);
  const convalidarFolRef = useRef(null);
  const envioUrgenteRef = useRef(null);
  const envioEstandarRef = useRef(null);
  const conAlertaRef = useRef(null);
  const noCertificadoEmailRef = useRef(null);
  const noCertificadoPostalRef = useRef(null);

  const certificadoStore = useContext(certificadosStore);
  const commonStore = useContext(CommonStore);
  const { cursosList, websList, searchCriteria } = certificadoStore;

  const handleSubmit = async () => {
    const searchCriteria: ISearchCertificados = {
      nombreAlumno: nombreRef.current.inputRef.current.value,
      dniAlumno: dniRef.current.inputRef.current.value,
      emailAlumno: emailRef.current.inputRef.current.value,
      telefonoAlumno: telefonoRef.current.inputRef.current.value,
      codigoDescuento: descuentoRef.current.inputRef.current.value,
      fechaInicio: getFilterDate(
        dateRangeRef.current.inputRef.current.inputRef.current.value,
        true
      ),
      fechaFin: getFilterDate(
        dateRangeRef.current.inputRef.current.inputRef.current.value,
        false
      ),
      medioPago: getSelectedMediosPago(),
      estadoPago: getSelectedEstadosPago(),
      webs: websRef.current.state.value,
      cursos: cursosRef.current.state.value,
      convalidarFol: convalidarFolRef.current.inputRef.current.checked,
      envioUrgente: envioUrgenteRef.current.inputRef.current.checked,
      envioEstandar: envioEstandarRef.current.inputRef.current.checked,
      conAlerta: conAlertaRef.current.inputRef.current.checked,
      noCertificadoEmail: noCertificadoEmailRef.current.inputRef.current.checked,
      noCertificadoPostal: noCertificadoPostalRef.current.inputRef.current.checked
    };

    commonStore.setLoadingStatus(true);
    certificadoStore.setSearchCertificadosCriteria(searchCriteria);
    await props.handleSearch(searchCriteria);
    commonStore.setLoadingStatus(false);
  };

  const getInitialDates = (): Date[] => {
    if (searchCriteria !== null)
      return [new Date(searchCriteria.fechaInicio), new Date(searchCriteria.fechaFin)]

    let initialDate = getDatePickerDefaultDates();
    return initialDate;
  }

  const getSelectedMediosPago = (): number[] => {
    const selectedValues = medioPagoRef.current.state.value as number[];
    
    if (selectedValues.length === 0)
      return [] as number[];
    
    if (selectedValues.includes(1))
      return MediosPagoDropdown.map(option => option.value)

    return selectedValues;
  }

  const getSelectedEstadosPago = (): number[] => {
    let selectedEstadosPago: number[] = [];
    const selectedValues = estadoPagoRef.current.state.value as number[];

    if (selectedValues.length === 0)
      return selectedEstadosPago;
    
    if (selectedValues.includes(0))
      return selectedEstadosPago.concat(EstadosPagoPaid, EstadosPagoPending, EstadosPagoRejected);

    if (selectedValues.includes(1))
      selectedEstadosPago = selectedEstadosPago.concat(EstadosPagoPaid);

    if (selectedValues.includes(2))
      selectedEstadosPago = selectedEstadosPago.concat(EstadosPagoPending);

    if (selectedValues.includes(3))
      selectedEstadosPago = selectedEstadosPago.concat(EstadosPagoRejected);

    return selectedEstadosPago;
  }

  return (
    <Grid columns={4}>
      <Grid.Row stretched>
        <Grid.Column>
          <Input label="Nombre Alumno" ref={nombreRef} defaultValue={searchCriteria !== null ? searchCriteria.nombreAlumno : ""} />
        </Grid.Column>
        <Grid.Column>
          <Input label="DNI Alumno" ref={dniRef} defaultValue={searchCriteria !== null ? searchCriteria.dniAlumno : ""} />
        </Grid.Column>
        <Grid.Column>
          <Input label="Telefono" ref={telefonoRef} defaultValue={searchCriteria !== null ? searchCriteria.telefonoAlumno : ""} />
        </Grid.Column>
        <Grid.Column>
          <Input label="Email" ref={emailRef} defaultValue={searchCriteria !== null ? searchCriteria.emailAlumno : ""} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={4}>
        <Grid.Column>
          <Input label="Cod. Descuento" ref={descuentoRef} defaultValue={searchCriteria !== null ? searchCriteria.codigoDescuento : ""} />
        </Grid.Column>
        <Grid.Column>
          <div className="ui labeled input">
            <div className="ui label label">Fechas</div>
            <SemanticDatepicker
              className="DatePicker"
              placeholder="DD/MM/YYYY - DD/MM/YYYY"
              format="DD/MM/YYYY"
              locale="es-ES"
              ref={dateRangeRef}
              type="range"
              datePickerOnly
              value={getInitialDates()}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="ui labeled input">
            <div className="ui label label">Medio de Pago</div>
            <Dropdown
              selection
              multiple
              className="MultiselectDropdowns"
              options={MediosPagoDropdown}
              ref={medioPagoRef}
              defaultValue={searchCriteria !== null ? searchCriteria.medioPago : [] as number[]}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="ui labeled input">
            <div className="ui label label">Estado de Pago</div>
            <Dropdown
              selection
              multiple
              className="MultiselectDropdowns"
              options={EstadosPagoDropdown}
              ref={estadoPagoRef}
              defaultValue={searchCriteria !== null ? searchCriteria.estadoPago : [] as number[]}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div className="ui labeled input" style={{ width: '100%' }}>
            <div className="ui label label">Webs</div>
            <Dropdown
              multiple
              selection
              options={websList}
              ref={websRef}
              className="MultiselectDropdowns"
              defaultValue={searchCriteria !== null ? searchCriteria.webs : websList.map((web) => { return web.value })}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="ui labeled input" style={{ width: '100%' }}>
            <div className="ui label label">Cursos</div>
            <Dropdown
              multiple
              selection
              options={cursosList}
              ref={cursosRef}
              className="MultiselectDropdowns"
              defaultValue={searchCriteria !== null ? searchCriteria.cursos : cursosList.map((curso) => { return curso.value })}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={6}>
        <Grid.Column><Checkbox label='Convalidar Fol' ref={convalidarFolRef} defaultValue={searchCriteria !== null ? searchCriteria.convalidarFol : false} /></Grid.Column>
        <Grid.Column><Checkbox label='Envio Urgente' ref={envioUrgenteRef} defaultValue={searchCriteria !== null ? searchCriteria.envioUrgente : false} /></Grid.Column>
        <Grid.Column><Checkbox label='Envio Estandar' ref={envioEstandarRef} defaultValue={searchCriteria !== null ? searchCriteria.envioEstandar : false} /></Grid.Column>
        <Grid.Column><Checkbox label='Con Alerta' ref={conAlertaRef} defaultValue={searchCriteria !== null ? searchCriteria.conAlerta : false} /></Grid.Column>
        <Grid.Column><Checkbox label='Sin Certificado Email' ref={noCertificadoEmailRef} defaultValue={searchCriteria !== null ? searchCriteria.noCertificadoEmail : false} /></Grid.Column>
        <Grid.Column><Checkbox label='Sin Certificado Postal' ref={noCertificadoPostalRef} defaultValue={searchCriteria !== null ? searchCriteria.noCertificadoPostal : false} /></Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1} centered>
        <Icon className="iconButton" name='arrow alternate circle up outline' size="big" onClick={() => { props.handleSetOpenStatus(false) }} />
        <Button className='action-button' onClick={handleSubmit}>Buscar</Button>
      </Grid.Row>
    </Grid>
  );
};

export default observer(CertificadosSearch)
