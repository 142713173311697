import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { Button, Checkbox, Divider, Dropdown, Input, Modal } from 'semantic-ui-react';
import { ISendEmailAlumnoRequestRequest } from '../../../models/certificado';
import certificadosStore from '../../../stores/CertificadosStore';
import { showErrorToast, showSuccessToast } from '../../../functions/CommonFunctions';
import CommonStore from '../../../stores/CommonStore';

interface ISendEmailAlumnoModalProps {
    visible: boolean;
    handleVisibleStatus: (open: boolean) => void;
    headerText: string,
    contentText: string,
    isRecordatorio: boolean,
    action: (request: ISendEmailAlumnoRequestRequest) => Promise<void>,
}

const SendEmailAlumnoModal: React.FC<ISendEmailAlumnoModalProps> = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const certificadoStore = useContext(certificadosStore);
    const { certificadosSelectedList, emailTemplatesList } = certificadoStore;
    const commonStore = useContext(CommonStore);
    const isSignedRef = useRef(null);
    const emailTemplateRef = useRef(null);
    const emailAlternativoRef = useRef(null);

    const printCertificados = async () => {
        const sendEmailRequest: ISendEmailAlumnoRequestRequest = {
            ids: certificadosSelectedList,
            PlantillaEmailId: emailTemplateRef.current.state.value,
            IsSigned: isSignedRef.current.inputRef.current.checked,
            emailAlternativo: emailAlternativoRef.current.inputRef.current.value
        };

        try {
            setSubmitting(true);
            commonStore.setLoadingStatus(true);
            await props.action(sendEmailRequest);
            showSuccessToast('Email enviado correctamente');
        }
        catch {
            showErrorToast('Se ha producido un error al enviar el email');
        }
        setSubmitting(false);
        props.handleVisibleStatus(false);
        commonStore.setLoadingStatus(false);
    }

    return (
        <Modal open={props.visible} size='small' style={{width: '45%'}}>
            <Modal.Header>{props.headerText}</Modal.Header>
            <Modal.Content>
                {props.contentText}
                <Divider hidden />
                <Dropdown
                    style={{width: '30%'}}
                    selection
                    placeholder='Seleccione una plantilla'
                    options={emailTemplatesList}
                    ref={emailTemplateRef}
                />
                <Input placeholder="Email Alternativo" style={{
                    marginLeft: '1em',
                    width: '30%'
                }} ref={emailAlternativoRef} />
                <Checkbox
                    toggle
                    name='signed'
                    label="Firmar PDF"
                    style={{
                        visibility: props.isRecordatorio
                            ? "hidden"
                            : "visible",
                        marginLeft: '1em'
                    }}
                    ref={isSignedRef}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => props.handleVisibleStatus(false)}>
                    Cancelar
                </Button>
                <Button positive onClick={() => printCertificados()} disabled={submitting}>
                    Enviar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default observer(SendEmailAlumnoModal);