import { Fragment } from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { IEmailTemplate } from '../../../models/Maintenance';

interface IEmailTemplateListProps {
    handleOpenEditTemplateForm: (id: number) => void;
    emailTemplateList: IEmailTemplate[];
}

const EmailTemplatesList: React.FC<IEmailTemplateListProps> = (props) => {
    const getTipoPlantillaEmail = (id: number): string => {
        switch (id) {
            case 1: {
                return "Recordatorio de pago";
            }
            case 2: {
                return "Envío de certificado";
            }
            case 3: {
                return "Avisos";
            }
        }
    }

    return (
        <Fragment>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Tipo</Table.HeaderCell>
                        <Table.HeaderCell>Nombre</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.emailTemplateList.map((emailTemplate) => (
                        <Table.Row key={emailTemplate.id}>
                            <Table.Cell>{getTipoPlantillaEmail(emailTemplate.tipoPlantillaEmailId)}</Table.Cell>
                            <Table.Cell>{emailTemplate.nombre}</Table.Cell>
                            <Table.Cell>
                                <Icon
                                    name="edit"
                                    size="large"
                                    className="iconButton"
                                    onClick={() => { props.handleOpenEditTemplateForm(emailTemplate.id) }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Fragment>
    )
}

export default EmailTemplatesList