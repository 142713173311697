import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestHeaders, Method } from "axios";
import {
  ICertificado,
  ICertificadoDetails,
  IChangeEstadoPagoRequest,
  IPrintExportCertificadosRequest,
  ISearchCertificados,
  ISendEmailAlumnoRequestRequest,
  IUpdateCertificadoRequest
} from "../models/certificado";
import { IDropdownOption } from "../models/dropdownOptions";
import { ICurso, IEmailTemplate, IMapeoCampo, IPrintTemplate, IWeb, IWebCurso } from "../models/Maintenance";
import { IAlumno, ISearchAlumnos } from "../models/Alumno";
import { msalInstance, scopes } from "../authentication/msal";
import { IErrorSync } from "../models/ErrorSync";
import { IBeneficiosSummary, IComparativaFechas, IEstadoPagoSummary, IMedioPagoSummary } from "../models/metricas";

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

axios.interceptors.request.use(async (config: AdaptAxiosRequestConfig) => {

  const accessTokenResponse = await msalInstance.acquireTokenSilent({ scopes: scopes, account: msalInstance.getAllAccounts()[0] });
  const accessToken = accessTokenResponse.accessToken;

  if (config.headers && accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config
});

const responseBody = (response: AxiosResponse) => response.data;

const executeRequest = (method: Method, url: string, body?: any) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

  switch (method) {
    case 'GET': {
      return axios.get(url).then(responseBody);
    }
    case 'POST': {
      return axios.post(url, body).then(responseBody);
    }
    case 'DELETE': {
      return axios.delete(url).then(responseBody);
    }
    case 'PUT': {
      return axios.put(url, body).then(responseBody);
    }
  }

}

const requests = {
  get: (url: string) => executeRequest('GET', url),
  post: (url: string, body?: {}): any => executeRequest('POST', url, body),
  put: (url: string, body: {}) => executeRequest('PUT', url, body),
  del: (url: string) => executeRequest('DELETE', url),
};

const Alumnos = {
  search: (searchAlumnos?: ISearchAlumnos): Promise<IAlumno[]> =>
    requests.post("/alumnos/search", searchAlumnos),
  certificadosAlumno: (alumnoId: number): Promise<ICertificado[]> =>
    requests.get(`/alumnos/certificadosAlumno?alumnoId=${alumnoId}`),
};

const Certificados = {
  search: (searchCertificados?: ISearchCertificados): Promise<ICertificado[]> =>
    requests.post("/certificados/search", searchCertificados),
  details: (id: number): Promise<ICertificadoDetails> =>
    requests.get(`/certificados/details?certificadoId=${id}`),
  save: (id: number, updateRequest: IUpdateCertificadoRequest) => requests.post(`/certificados/save?certificadoId=${id}`, updateRequest),
  changeEstadoPago: (changeEstadoPagoRequest: IChangeEstadoPagoRequest) => requests.post("/certificados/changeEstadoPago", changeEstadoPagoRequest),
  printCertificados: (printCertificadosRequest: IPrintExportCertificadosRequest) => requests.post("/certificados/printcertificados", printCertificadosRequest),
  printEtiquetas: (printEtiquetasRequest: IPrintExportCertificadosRequest) => requests.post("/certificados/printetiquetas", printEtiquetasRequest),
  exportCertificados: (exportCertificadosRequest: IPrintExportCertificadosRequest) => requests.post("/certificados/exportcertificados", exportCertificadosRequest),
  sendRecordatorio: (sendEmailRequest: ISendEmailAlumnoRequestRequest) => requests.post("/certificados/sendrecordatoriopago", sendEmailRequest),
  sendCertificadoEmail: (sendEmailRequest: ISendEmailAlumnoRequestRequest) => requests.post("/certificados/sendcertificadoemail", sendEmailRequest),
};

const Desplegables = {
  mediosPago: (): Promise<IDropdownOption> => requests.get(`/desplegables/mediospago`),
  estadosPago: (): Promise<IDropdownOption> => requests.get(`/desplegables/estadospago`),
  cursos: (): Promise<IDropdownOption[]> => requests.get(`/desplegables/cursos`),
  webs: (): Promise<IDropdownOption[]> => requests.get(`/desplegables/webs`),
  emailTemplates: (): Promise<IDropdownOption> => requests.get(`/desplegables/emailtemplates`),
  printTemplates: (): Promise<IDropdownOption[]> => requests.get(`/desplegables/printtemplates`)
}

const EmailTemplates = {
  list: (): Promise<IEmailTemplate[]> => requests.get(`/maintenance/emailtemplates/list`),
  details: (id: number): Promise<IEmailTemplate> => requests.get(`/maintenance/emailtemplates/details?emailTemplateId=${id}`),
  save: (emailTemplate: IEmailTemplate) => requests.post(`/maintenance/emailtemplates/save`, emailTemplate),
  delete: (id: number) => requests.del(`/maintenance/emailtemplates/delete?emailTemplateId=${id}`)
}

const PrintTemplates = {
  list: (): Promise<IPrintTemplate[]> => requests.get(`/maintenance/printtemplates/list`),
  details: (id: number): Promise<IPrintTemplate> => requests.get(`/maintenance/printtemplates/details?printTemplateId=${id}`),
  save: (printTemplate: IPrintTemplate) => requests.post(`/maintenance/printtemplates/save`, printTemplate),
  delete: (id: number) => requests.del(`/maintenance/printtemplates/delete?printTemplateId=${id}`)
}

const Webs = {
  list: (): Promise<IWeb[]> => requests.get(`/maintenance/webs/list`),
  details: (id: number): Promise<IWeb> => requests.get(`/maintenance/webs/details?webId=${id}`),
  save: (web: IWeb) => requests.post(`/maintenance/webs/save`, web),
  delete: (id: number) => requests.del(`/maintenance/webs/delete?webId=${id}`)
}

const Cursos = {
  list: (): Promise<ICurso[]> => requests.get(`/maintenance/cursos/list`),
  details: (id: number): Promise<ICurso> => requests.get(`/maintenance/cursos/details?cursoId=${id}`),
  save: (curso: ICurso) => requests.post(`/maintenance/cursos/save`, curso),
  delete: (id: number) => requests.del(`/maintenance/cursos/delete?cursoId=${id}`)
}

const WebCursos = {
  list: (webId: number): Promise<IWebCurso[]> => requests.get(`/maintenance/webcursos/list?webId=${webId}`),
  details: (id: number): Promise<ICurso> => requests.get(`/maintenance/webcursos/details?webCursoId=${id}`),
  save: (webCurso: IWebCurso) => requests.post(`/maintenance/webcursos/save`, webCurso),
  delete: (id: number) => requests.del(`/maintenance/webcursos/delete?webCursoId=${id}`),
  listPlantillasImpresion: (webCursoid: number): Promise<number[]> => requests.get(`/maintenance/webcursos/plantillasimpresion/list?webCursoId=${webCursoid}`),
  savePlantillasImpresion: (webCursoid: number, plantillasImpresionIds: number[]) => requests.post(`/maintenance/webcursos/plantillasimpresion/save?webCursoId=${webCursoid}`, plantillasImpresionIds)
}

const MapeoCampos = {
  list: (webCursoId: number): Promise<IMapeoCampo[]> => requests.get(`/maintenance/mapeocampos/list?webCursoId=${webCursoId}`),
  save: (mapeos: IMapeoCampo[]) => requests.post(`/maintenance/mapeocampos/save`, mapeos)
}

const ErroresSync = {
  list: (): Promise<IErrorSync[]> => requests.get(`/erroressync/search`),
  retry: (errorsList: number[]) => requests.post(`/erroressync/retryerrors`, errorsList)
}

const Metrics = {
  estadoPago: (searchCertificados: ISearchCertificados): Promise<IEstadoPagoSummary[]> =>
    requests.post("/metricas/estadopago", searchCertificados),
  medioPago: (searchCertificados: ISearchCertificados): Promise<IMedioPagoSummary[]> =>
    requests.post("/metricas/mediopago", searchCertificados),
  beneficios: (searchCertificados: ISearchCertificados): Promise<IBeneficiosSummary[]> =>
    requests.post("/metricas/beneficios", searchCertificados),
  comparativa: (filtros: ISearchCertificados, startDateToCompare: string, endDateToCompare: string): Promise<IComparativaFechas[]> =>
    requests.post("/metricas/comparativa", {filtros, startDateToCompare, endDateToCompare}),
};

const agent = {
  Alumnos,
  Certificados,
  Desplegables,
  EmailTemplates,
  PrintTemplates,
  Webs,
  Cursos,
  WebCursos,
  MapeoCampos,
  ErroresSync,
  Metrics,
};

export default agent;
